import React, { useEffect, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Chart from 'chart.js/auto';
import { DateTime } from 'luxon';
import './CustomerMaterialLineChart.css'

const CustomerMaterialLineChart = () => {
  const location = useLocation();
  const weeeData = useMemo(() => location.state?.weeeData || [], [location.state?.weeeData]);
  const ceramicsData = useMemo(() => location.state?.ceramicsData || [], [location.state?.ceramicsData]);

  const weeeChartRef = useRef(null);
  const ceramicsChartRef = useRef(null);

  useEffect(() => {
    // Extract dates and kilograms from the WEEE data only
    const weeeDates = weeeData.map((entry) => DateTime.fromISO(entry.date).toMillis());
    const weeeKilograms = weeeData.map((entry) => entry.kilograms);

    // Destroy existing WEEE chart if it exists
    if (weeeChartRef.current) {
      weeeChartRef.current.destroy();
    }

    // Create a line chart for WEEE data
    const weeeCtx = document.getElementById('weeeLineChart').getContext('2d');
    weeeChartRef.current = new Chart(weeeCtx, {
      type: 'line',
      data: {
        labels: weeeDates,
        datasets: [
          {
            label: 'WEEE Data',
            data: weeeKilograms,
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 2,
            fill: false,
          },
        ],
      },
      options: {
        layout: {
        },
        scales: {
          x: {
            type: 'linear',
            title: {
              display: true,
              text: 'Date',
            },
            ticks: {
              callback: (value) => DateTime.fromMillis(value).toLocaleString(),
            },
          },
          y: {
            title: {
              display: true,
              text: 'Kilograms',
            },
          },
        },
      },
    });
    
  }, [weeeData]);

  useEffect(() => {
    // Extract dates and kilograms from the ceramics data only
    const ceramicsDates = ceramicsData.map((entry) => DateTime.fromISO(entry.date).toMillis());
    const ceramicsKilograms = ceramicsData.map((entry) => entry.kilograms);

    // Destroy existing ceramics chart if it exists
    if (ceramicsChartRef.current) {
      ceramicsChartRef.current.destroy();
    }

    // Create a line chart for ceramics data
    const ceramicsCtx = document.getElementById('ceramicsLineChart').getContext('2d');
    ceramicsChartRef.current = new Chart(ceramicsCtx, {
      type: 'line',
      data: {
        labels: ceramicsDates,
        datasets: [
          {
            label: 'Ceramics Data',
            data: ceramicsKilograms,
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 2,
            fill: false,
          },
        ],
      },
      options: {
        layout: {
        },
        scales: {
          x: {
            type: 'linear',
            title: {
              display: true,
              text: 'Date',
            },
            ticks: {
              callback: (value) => DateTime.fromMillis(value).toLocaleString(),
            },
          },
          y: {
            title: {
              display: true,
              text: 'Kilograms',
            },
          },
        },
      },
    });
  }, [ceramicsData]);

  return (
    <div className='chartMain'>
      <canvas id="weeeLineChart"></canvas>
      <canvas id="ceramicsLineChart" ></canvas>
    </div>
  );
};

export default CustomerMaterialLineChart;
