import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './calendarWarehouse.css';

const CalendarView = () => {
  const [contactData, setContactData] = useState([]);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/contacts`);
        setContactData(response.data);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchContacts();
  }, [baseUrl]);

  // Get today's date and tomorrow's date
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  // Filter contacts for today and tomorrow with typeOfContact === 'Delivery'
  const filteredContactsToday = contactData.filter((contact) => {
    const contactDate = new Date(contact.nextContact);
    return (
      contactDate.toISOString().split('T')[0] === today.toISOString().split('T')[0] &&
      contact.typeOfContact === 'Delivery'
    );
  });

  const filteredContactsTomorrow = contactData.filter((contact) => {
    const contactDate = new Date(contact.nextContact);
    return (
      contactDate.toISOString().split('T')[0] === tomorrow.toISOString().split('T')[0] &&
      contact.typeOfContact === 'Delivery'
    );
  });

  return (
    <div className='warehouse-container'>
      <div className='warehouse-box-day'>
        <h2>Today - {today.toDateString()}</h2>
        {filteredContactsToday.map((contact) => (
          <div key={contact._id}>
    <span className="warehouse-contact-name">{contact.name}</span> - {''}
    <span className="warehouse-material-type">{contact.materialType}</span>
          </div>
        ))}
      </div>

      <div className='warehouse-box-day'>
        <h2>Tomorrow - {tomorrow.toDateString()}</h2>
        {filteredContactsTomorrow.map((contact) => (
          <div key={contact._id}>
    <span className="warehouse-contact-name">{contact.name}</span> - {''}
    <span className="warehouse-material-type">{contact.materialType}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarView;
