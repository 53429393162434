import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  let logoutTimer;

  const login = () => {
    // Clear the existing timer before starting a new one
    clearTimeout(logoutTimer);
    
    // Implement your login logic (e.g., call an authentication API)
    setLoggedIn(true);
    
    // Start the logout timer
    logoutTimer = setTimeout(logout, 57600000);
};


const logout = () => {
  // Clear the timer before logging out
  clearTimeout(logoutTimer);
  
  // Implement your logout logic
  setLoggedIn(false);
  
  // Navigate to the specified URL
  window.location.href = 'https://www.crg-egypt.com/';
};

  // Clean up the timer if the component unmounts
  useEffect(() => {
    return () => {
      clearTimeout(logoutTimer);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
