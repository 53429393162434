import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../export/export.css'
import { useAuth } from '../../../userAuth';
import axios from 'axios';

const CrgReportView = () => {
    const { isLoggedIn } = useAuth();
    const title = localStorage.title;
    const navigate = useNavigate();
    useEffect(() => {
      if (!isLoggedIn) {
        navigate('/login');
      }
    }, [isLoggedIn, navigate]);
    const [crgData, setCrgData] = useState(null);
    const [report, setReport] = useState(null);
    const [exports, setExports] = useState([]);
    const [addedLoad, setAddedLoad] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [expensesZero, setExpensesZero] = useState([]);
    const [debt, setDebt] = useState(null);
    const [fixedExpenses, setFixedExpenses] = useState([]);
    const [variableExpenses, setVariableExpenses] = useState([]);
    const [totalPtOz, setTotalPtOz] = useState(0);
    const [totalPdOz, setTotalPdOz] = useState(0);
    const [totalRhOz, setTotalRhOz] = useState(0);
    const [totalUsdSpentTotal, setTotalUsdSpentTotal] = useState(null);
    const [totalPtOzIcp, setTotalPtOzIcp] = useState(0);
    const [totalPdOzIcp, setTotalPdOzIcp] = useState(0);
    const [totalRhOzIcp, setTotalRhOzIcp] = useState(0);
    const [totalSpent, setTotalSpent] = useState(0);
    const [totalEstGrossCrg, setTotalEstGrossCrg] = useState(0);
    const [totalCrgProportionWeight, setTotalCrgProportionWeight] = useState(0);
    const [totalIcpWeight, setTotalIcpWeight] = useState(0);
    const [totalTechXrfWeight, setTotalTechXrfWeight] = useState(0);
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    const { reportId } = useParams();
    const isLocal = window.location.hostname === 'localhost';
    const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';

    useEffect(() => {
        fetchReport();
    }, []);

    const fetchReport = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/reports/${reportId}`);
            setReport(response.data);
    
            const exportLoad = response.data.exportLoad;
    
            // Fetch details for each export load
            const addedLoadDetails = await Promise.all(exportLoad.map(async (load) => {
                const exportItemResponse = await axios.get(`${baseUrl}/api/exports/reportloads/${load.exportId}`);  
                return exportItemResponse.data;
            }));
    
            // Calculate sum of spent, estGrossCrg, and crgProportion.weight
            const totalSpent = addedLoadDetails.reduce((acc, load) => acc + load.spent, 0);
            const totalEstGrossCrg = addedLoadDetails.reduce((acc, load) => acc + load.estGrossCrg, 0);
            const totalCrgProportionWeight = addedLoadDetails.reduce((acc, load) => acc + load.crgProportion.weight, 0);
            const totalIcpWeight = addedLoadDetails.reduce((acc, load) => acc + load.tIcp.weight, 0);
            const totalTechXrfWeight = addedLoadDetails.reduce((acc, load) => acc + load.tXrf.weight, 0);
            const totalUsdSpentTotal = addedLoadDetails.reduce((acc, load) => acc + load.lotsAll.usdSpentTotal, 0);
    
            // Calculate sum of oz values separately for ptOz, pdOz, and rhOz
            const totalPtOz = addedLoadDetails.reduce((acc, load) => acc + load.fixedLme.ptOz, 0);
            const totalPdOz = addedLoadDetails.reduce((acc, load) => acc + load.fixedLme.pdOz, 0);
            const totalRhOz = addedLoadDetails.reduce((acc, load) => acc + load.fixedLme.rhOz, 0);
            // Calculate sum of oz values separately for ptOz, pdOz, and rhOz
            const totalPtOzIcp = addedLoadDetails.reduce((acc, load) => acc + load.tIcp.ptOz, 0);
            const totalPdOzIcp = addedLoadDetails.reduce((acc, load) => acc + load.tIcp.pdOz, 0);
            const totalRhOzIcp = addedLoadDetails.reduce((acc, load) => acc + load.tIcp.rhOz, 0);
            // Set the sums in the state
            setTotalSpent(totalSpent);
            setTotalEstGrossCrg(totalEstGrossCrg);
            setTotalCrgProportionWeight(totalCrgProportionWeight);
            setTotalIcpWeight(totalIcpWeight);
            setTotalTechXrfWeight(totalTechXrfWeight);
            setTotalPtOz(totalPtOz); 
            setTotalPdOz(totalPdOz); 
            setTotalRhOz(totalRhOz); 
            setTotalPtOzIcp(totalPtOzIcp); 
            setTotalPdOzIcp(totalPdOzIcp); 
            setTotalRhOzIcp(totalRhOzIcp);
            setTotalUsdSpentTotal(totalUsdSpentTotal);
        
            setAddedLoad(addedLoadDetails);
            if (response.data && response.data.month) {
                const month = new Date(response.data.month);
                fetchExpensesForMonth(month);
                fetchAllExports();
                fetchDebt();
                fetchCrgData();
            }
        } catch (error) {
            console.error('Error fetching report:', error);
        }
    };

    const fetchDebt = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/crg`);
            setDebt(response.data.debtCrgTotalUsd);
        } catch (error) {
            console.error('Error fetching expense:', error);
        }
    };
    
    const fetchExpensesForMonth = async (month) => {
        try {
            const response = await axios.get(`${baseUrl}/api/forreport/expenses?month=${month.toISOString()}`);
            setExpenses(response.data);
            setExpensesZero(response.data[0]);
            setFixedExpenses(response.data[0].fixedExpenses);
            setVariableExpenses(response.data[0].variableExpenses);
        } catch (error) {
            console.error('Error fetching expenses:', error);
        }
    };

    const fetchAllExports = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/exports/newestfive`);
            setExports(response.data);
        } catch (error) {
            console.error('Error fetching exports:', error);
        }
    };

    const fetchCrgData = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/crg`);
            if (!response.ok) {
                throw new Error('Failed to fetch CRG data');
            }
            const crgData = await response.json();
            setCrgData(crgData);
        } catch (error) {
            console.error('Error fetching CRG data:', error);
        }
    };

    const sumUsdSpent = () => {
        if (!crgData || !report) return 0;
        const reportMonth = new Date(report.month).getMonth(); // Extract month from report date
        return crgData.ceramicsCrg.indCeramicsCrg.reduce((total, item) => {
            if (new Date(item.dateCrg).getMonth() === reportMonth) { // Compare with report's month
                return total + item.usdSpentCrg;
            }
            return total;
        }, 0);
    };
    

    const handleExportCheckboxChange = async (exportId, isChecked) => {
            try {
                setIsLoading(true);
                await axios.put(`${baseUrl}/api/reports/selected-exports/${reportId}`, { exportId });
                fetchReport(); // Fetch updated report after saving selected export
                setIsLoading(false); // Hide loading modal after successful save
            } catch (error) {
                console.error('Error saving export to report:', error);
                setIsLoading(false); // Hide loading modal on error
        }
    };


    const totalExpense = expenses.reduce((total, expense) => total + expense.totalUsdSpent + expense.totalUsdExport, 0);


    if (!report || !expenses || !exports) {
        return <div>Loading...</div>;
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Month starts from 0
        const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of year

        // Pad single digits with leading zero
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;

        return `${formattedDay}/${formattedMonth}/${year}`;
    }

    const handleExpenseClick = (expenseId) => {
        navigate(`/crg-monthly-expense/k;aslkdjfe[aigjfdhsktuwrept9w0ereur2i-wuarfiopskgjfkl;j/${expenseId}`);
    };

    const handleExportViewLoadClick = (id) => {
        navigate(`/crg-export-load-details/asd6ar5e459843y4tr65j4hk6yu5y4usfd5g1a+f56ds4f+af/${id}`);
    };


    return (
        <div className="report-view-main">
            {isLoggedIn && title === 'Admin' && (
    <>
            <h2 className='h1HeadersExportDetailsExpenses'>Report For {new Date(report.month).toLocaleString('default', { month: 'long', year: 'numeric' })}</h2>
            <div className='report-view-main-top-column'>
                
            <div className='report-view-main-middle-row'>  
            {expenses.map((expense, index) => (
                <div key={index} className="expense-item-report-click"  onClick={() => handleExpenseClick(expensesZero._id)}>
                    <h2 className='h1HeadersExportDetailsExpensesTitles'>Expenses</h2>
                    <p>Total ${(expense.totalUsdSpent + expense.totalUsdExport).toFixed(0)}</p>
                    <p>Fixed ${expense.totalUsdSpentFixed}</p>
                    <p>Variable ${expense.totalUsdSpentVariable}</p>
                    <p>Export ${(expense.totalUsdExport).toFixed(0)}</p>
                </div>
            ))}

            {expenses.map((expense, index) => (
            <div key={index} className="expense-item-report-click"  onClick={() => handleExpenseClick(expensesZero._id)}>
                <h2 className='h1HeadersExportDetailsExpensesTitles'>Accounting</h2>
                    <p>Purchases ${sumUsdSpent()}</p>
                    <p>Fixed ${expense.totalUsdSpentFixed}</p>
                    <p>Variable ${expense.totalUsdSpentVariable}</p>
                    <p>Export ${(expense.totalUsdExport).toFixed(0)}</p>
                </div>
            ))}
                <div className="expense-item-report-click"  onClick={() => handleExpenseClick(expensesZero._id)}>
                <h2 className='h1HeadersExportDetailsExpensesTitles'>Real</h2>
                    <p>Purchases ${totalUsdSpentTotal}</p>
                    <p>Gross ${totalEstGrossCrg - totalUsdSpentTotal}</p>
                    <p>Net ${totalEstGrossCrg - totalUsdSpentTotal - totalExpense}</p>
                    <p>Debt ${debt}</p>
                </div>

                <div className="expense-item-report-click"  onClick={() => handleExpenseClick(expensesZero._id)}>
                <h2 className='h1HeadersExportDetailsExpensesTitles'>Cash</h2>
                    <p>Open ${expensesZero ? expensesZero.openCash + expensesZero.openDebt : 0}</p>
                    <p>Money In ${expensesZero ? expensesZero.totalUsdInAll : 0}</p>
                    <p>Spent ${expensesZero ? sumUsdSpent() + expensesZero.totalUsdSpent + expensesZero.totalUsdExport : 0}</p>
                    <p>Balance ${expensesZero ? (expensesZero.totalUsdInAll + expensesZero.openDebt + expensesZero.openCash - debt - (sumUsdSpent() + expensesZero.totalUsdSpent + expensesZero.totalUsdExport)) : 0}</p>
                </div>
            </div>


            <div className='report-view-main-middle-row'>
            <div className="expense-item-report">
            <h2 className='h1HeadersExportDetailsExpensesTitles'>Fixed</h2>
                <p>Weight: {totalCrgProportionWeight  / 1000}</p>
                <p>Pt Oz: {totalPtOz.toFixed(2)}</p>
                <p>Pd Oz: {totalPdOz.toFixed(2)}</p>
                <p>Rh Oz: {totalRhOz.toFixed(2)}</p>
                </div>
          
                <div className="expense-item-report">
                <h2 className='h1HeadersExportDetailsExpensesTitles'>Recovered ICP</h2>
                <p>Weight: {totalTechXrfWeight}</p>
                <p>Pt Oz: {totalPtOzIcp.toFixed(2)}</p>
                <p>Pd Oz: {totalPdOzIcp.toFixed(2)}</p>
                <p>Rh Oz: {totalRhOzIcp.toFixed(2)}</p>
                </div>
                <div className="expense-item-report">
                <h2 className='h1HeadersExportDetailsExpensesTitles'>Balance</h2>
                <p>Weight: {(totalIcpWeight - totalCrgProportionWeight) / 1000}</p>
                <p>Pt Oz: {totalPtOz.toFixed(2) - totalPtOzIcp.toFixed(2)}</p>
                <p>Pd Oz: {totalPdOz.toFixed(2) - totalPdOzIcp.toFixed(2)}</p>
                <p>Rh Oz: {totalRhOz.toFixed(2) - totalRhOzIcp.toFixed(2)}</p>
                </div>
                <div className="expense-item-report">
                    <h2 className='h1HeadersExportDetailsExpensesTitles'>Add Loads</h2>
                    {exports
                        .filter((exportItem) => !report.exportLoad.some((load) => load.exportId === exportItem._id))
                        .map((exportItem, index) => (
                        <div key={index}>
                            <input 
                            type="checkbox" 
                            id={`exportCheckbox${index}`} 
                            name={`exportCheckbox${index}`} 
                            onChange={(e) => handleExportCheckboxChange(exportItem._id, e.target.checked)} 
                            />
                            <label htmlFor={`exportCheckbox${index}`}>Load: {exportItem.load}</label>
                            {/* Render other export details as needed */}
                        </div>
                    ))}
                    </div>
                </div>
                    </div>




            

                    <div className='report-view-main-middle-column'>
                    <h2 className='h1HeadersExportDetailsExpenses'>CRG XRF</h2>
                    <table className='table50'>
                    <thead className='table50'>
                        <tr className='table50'>
                        <th className='table50header'>Load</th>
                            <th className='table50header'>Date</th>
                            <th className='table50header'>Kilograms</th>
                            <th className='table50header'>Spent</th>
                            <th className='table50header'>Gross</th>
                            <th className='table50header'>Net</th>
                            <th className='table50header'>Pt Oz</th>
                            <th className='table50header'>Pd Oz</th>
                            <th className='table50header'>Rh Oz</th>
                            <th className='table50header'>Remove</th>
                        </tr>
                    </thead>
                    <tbody className='table50'>
                        {addedLoad.map((addedLoad, index) => (
                            <tr className='table50Pointer' key={index} onClick={() => handleExportViewLoadClick(addedLoad._id)}>
                                <td className='inputExportDetailsDate'>{addedLoad.load}</td>
                                <td className='inputExportDetailsDate'>{formatDate(addedLoad.created)}</td>
                                <td className='inputExportDetailsDate'>{addedLoad.lotsAll.kilogramTotal.toFixed(2)}</td>
                                <td className='inputExportDetails'> ${addedLoad.lotsAll.usdSpentTotal}</td>
                                <td className='inputExportDetails'> ${addedLoad.estGrossCrg - addedLoad.lotsAll.usdSpentTotal}</td>
                                <td className='inputExportDetails'> ${addedLoad.estGrossCrg - addedLoad.lotsAll.usdSpentTotal - addedLoad.exportExpenses - addedLoad.variableExpenses - addedLoad.fixedExpenses}</td>
                                <td className='inputExportDetails'>{addedLoad.crgXrf.ptOz}</td>
                                <td className='inputExportDetails'>{addedLoad.crgXrf.pdOz}</td>
                                <td className='inputExportDetails'>{addedLoad.crgXrf.rhOz}</td>
                                <td className='inputExportDetails'>
                                <input 
                                type="checkbox"
                                id={`exportCheckbox${index}`} 
                                name={`exportCheckbox${index}`} 
                                onChange={(e) => handleExportCheckboxChange(addedLoad._id, e.target.checked)} 
                                checked={true} // Set checked attribute to true
                            />
                                    </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                    </div>

                    <div className='report-view-main-middle-column'>
                    <h2 className='h1HeadersExportDetailsExpenses'>Tech XRF</h2>
                    <table className='table50'>
                    <thead className='table50'>
                        <tr className='table50'>
                        <th className='table50header'>Load</th>
                            <th className='table50header'>Date</th>
                            <th className='table50header'>Kilograms</th>
                            <th className='table50header'>Spent</th>
                            <th className='table50header'>Gross</th>
                            <th className='table50header'>Net</th>
                            <th className='table50header'>Pt Oz</th>
                            <th className='table50header'>Pd Oz</th>
                            <th className='table50header'>Rh Oz</th>
                        </tr>
                    </thead>
                    <tbody className='table50'>
                        {addedLoad.map((addedLoad, index) => (
                            <tr className='table50Pointer' key={index} onClick={() => handleExportViewLoadClick(addedLoad._id)}>
                                <td className='inputExportDetailsDate'>{addedLoad.load}</td>
                                <td className='inputExportDetailsDate'>{formatDate(addedLoad.created)}</td>
                                <td className='inputExportDetailsDate'>{addedLoad.tXrf.weight}</td>
                                <td className='inputExportDetails'> ${addedLoad.lotsAll.usdSpentTotal}</td>
                                <td className='inputExportDetails'> ${addedLoad.estGrossTechXrf - addedLoad.lotsAll.usdSpentTotal}</td>
                                <td className='inputExportDetails'> ${addedLoad.estGrossTechXrf - addedLoad.lotsAll.usdSpentTotal - addedLoad.exportExpenses - addedLoad.variableExpenses - addedLoad.fixedExpenses}</td>
                                <td className='inputExportDetails'>{addedLoad.tXrf.ptOz}</td>
                                <td className='inputExportDetails'>{addedLoad.tXrf.pdOz}</td>
                                <td className='inputExportDetails'>{addedLoad.tXrf.rhOz}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
                <div className='report-view-main-middle-column'>
                <h2 className='h1HeadersExportDetailsExpenses'>Tech ICP</h2>
                    <table className='table50'>
                    <thead className='table50'>
                        <tr className='table50'>
                        <th className='table50header'>Load</th>
                            <th className='table50header'>Date</th>
                            <th className='table50header'>Kilograms</th>
                            <th className='table50header'>Spent</th>
                            <th className='table50header'>Gross</th>
                            <th className='table50header'>Net</th>
                            <th className='table50header'>Pt Oz</th>
                            <th className='table50header'>Pd Oz</th>
                            <th className='table50header'>Rh Oz</th>
                        </tr>
                    </thead>
                    <tbody className='table50'>
                        {addedLoad.map((addedLoad, index) => (
                            <tr className='table50Pointer' key={index} onClick={() => handleExportViewLoadClick(addedLoad._id)}>
                                <td className='inputExportDetailsDate'>{addedLoad.load}</td>
                                <td className='inputExportDetailsDate'>{formatDate(addedLoad.created)}</td>
                                <td className='inputExportDetailsDate'>{addedLoad.tIcp.weight}</td>
                                <td className='inputExportDetails'> ${addedLoad.lotsAll.usdSpentTotal}</td>
                                <td className='inputExportDetails'> ${addedLoad.estGrossTechIcP - addedLoad.lotsAll.usdSpentTotal}</td>
                                <td className='inputExportDetails'> ${addedLoad.estGrossTechIcP - addedLoad.lotsAll.usdSpentTotal - addedLoad.exportExpenses - addedLoad.variableExpenses - addedLoad.fixedExpenses}</td>
                                <td className='inputExportDetails'>{addedLoad.tIcp.ptOz}</td>
                                <td className='inputExportDetails'>{addedLoad.tIcp.pdOz}</td>
                                <td className='inputExportDetails'>{addedLoad.tIcp.rhOz}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>



                <div className='exportDetailsTopRowExpenses'>
            <div className='exportExpensesBox'>
            <h2 className='h1HeadersExportDetailsExpenses'>Fixed Expenses</h2>
            <table className='table50'>
                    <thead className='table50'>
                        <tr className='table50'>
                            <th className='table50header'>Date</th>
                            <th className='table50header'>Type</th>
                            <th className='table50header'>Load</th>
                            <th className='table50header'>Spent</th>
                        </tr>
                    </thead>
                    <tbody className='table50'>
                        {fixedExpenses.map((item, index) => (
                            <tr className='table50' key={index}>
                                <td className='inputExportDetailsDate'>{formatDate(item.created)}</td>
                                <td className='inputExportDetailsType'>{item.type}</td>
                                <td className='inputExportDetailsDate'>{item.exportLoad.load}</td>
                                <td className='inputExportDetails'>$ {item.spentUsd}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                </div>
            <div className='exportExpensesBox'>
            <h2 className='h1HeadersExportDetailsExpenses'>Variable Expenses</h2>
            <table className='table50'>
                    <thead className='table50'>
                        <tr className='table50'>
                            <th className='table50header'>Date</th>
                            <th className='table50header'>Type</th>
                            <th className='table50header'>Load</th>
                            <th className='table50header'>Spent</th>
                        </tr>
                    </thead>
                    <tbody className='table50'>
                        {variableExpenses.map((item, index) => (
                            <tr className='table50' key={index}>
                                <td className='inputExportDetailsDate'>{formatDate(item.created)}</td>
                                <td className='inputExportDetailsType'>{item.type}</td>
                                <td className='inputExportDetailsDate'>{item.exportLoad.load}</td>
                                <td className='inputExportDetails'>$ {item.spentUsd}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            </div>

                    

                    
            {isLoading && <div className="modal">Saving...</div>} {/* Show modal while saving */}

            </>
            )}
        </div>
    );
};

export default CrgReportView;
