import React from 'react';
import { useNavigate } from 'react-router-dom';
import './products.css';

const Products = () => {
  const navigate = useNavigate();

  const handleCatalyticConvertersClick = () => {
    // Navigate to the specified URL
    window.location.href = 'https://crgtrading-8253692c6089.herokuapp.com/';
  };

  const handleElectronicClick = () => {
    // Use navigate function to go to the '/weemain' route
    navigate('/weee');
  };

  return (
    <div className="container">
      <div className="item catalytic" onClick={handleCatalyticConvertersClick}>
        <div className="content">
          <img src="/catalytic_converter_image.jpg" alt="Catalytic Converter" />
          <p className='description'>Unlock the value in your used catalytic converters with our professional recycling services. We specialize in extracting precious metals from catalytic converters, offering an environmentally responsible solution for automotive recycling. Partner with us to turn your end-of-life catalytic converters into a sustainable source of income while contributing to a greener future.</p>
        </div>
      </div>

      <div className="item electronic" onClick={handleElectronicClick}>
        <div className="content">
          <img src="/electronic_scrap_image.jpg" alt="Electronic Scrap" />
          <p className='description'>Join us in fostering sustainability by responsibly collecting and recycling electronic scrap. Your partnership with our services contributes to a cleaner environment, ensuring the proper disposal and recycling of electronic waste for a greener future.</p>
        </div>
        </div>
        <div className="item catalytic" onClick={handleCatalyticConvertersClick}>
        <div className="content">
          <img src="/censors.jpg" alt="Catalytic Converter" />
          <p className='description'>Elevate your commitment to environmental responsibility with our dedicated recycling services for oxygen sensors. Safeguarding the planet, we specialize in the collection and recycling of oxygen sensors, ensuring their proper disposal and contributing to a sustainable and eco-friendly future. Partner with us to make a positive impact on the environment through responsible and efficient recycling practices</p>
        </div>
    
      </div>
    </div>
  );
};

export default Products;
