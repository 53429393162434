import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import { useAuth } from '../userAuth'; 
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import './calendarCustomer.css';

const CalendarPredictView = () => {
  const { isLoggedIn } = useAuth();
  const [date, setDate] = useState(new Date());
  const [contactData, setContactData] = useState([]);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = location.state?.userInfo || {};

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/contacts`);
        setContactData(response.data);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchContacts();
  }, [baseUrl]);

  const handleContactClick = (contactId) => {
    navigate(`/contact-details/${contactId}`, { state: { userInfo } });
  };

  return (
    <div className='calendarMain'>
      <Calendar
        onChange={setDate}
        value={date}
        tileContent={({ date }) => {
          const formattedDate = date.toLocaleDateString();
          const contactsOnDate = contactData.filter((contact) => {
            const contactDate = new Date(contact.nextPurchase);
            return contactDate.toLocaleDateString() === formattedDate;
          });
  
          return (
            <div className="event-marker">
              {contactsOnDate.map((contact) => (
                <div
                  key={contact._id}
                  className={`contact-event ${contact.typeOfContact.toLowerCase()}`}
                  onClick={() => handleContactClick(contact._id)}
                >
                  {contact.name}
                  <FontAwesomeIcon icon={faArrowRight} className="visitIcon" />
                </div>
              ))}
            </div>
          );
        }}
      />
    </div>
  );
};

export default CalendarPredictView;
