import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto'; 
import './currentgoal.css';

const CurrentGoal = () => {
    const [goalData, setGoalData] = useState(null);
    const [crgData, setCrgData] = useState(null);
    const [exportsData, setExportsData] = useState(null);
    const [totalWeightReal, setTotalWeightReal] = useState(null);
    const [totalGrossReal, setTotalGrossReal] = useState(null);
    const [totalVariableExpensesReal, setTotalVariableExpensesReal] = useState(null);
    const [totalFixedExpensesReal, setTotalFixedExpensesReal] = useState(null);
    const [totalUsdSpentFixed, setTotalUsdSpentFixed] = useState(null);
    const [totalUsdSpentVariable, setTotalUsdSpentVariable] = useState(null);
    const [totalExportExpensesReal, setTotalExportExpensesReal] = useState(null);
    const [totalSpentReal, setTotalSpentReal] = useState(null);
    const [progressValue, setProgressValue] = useState(null);
    const [expensesData, setExpensesData] = useState(null);
    const [realValues, setRealValues] = useState({
        kilogramsReal: 0,
        grossProfitReal: 0,
        fixedExpensesReal: 0,
        variableExpensesReal: 0,
        exportExpensesReal: 0
    });
    const isLocal = window.location.hostname === 'localhost';
    const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';

    const fetchCurrentMonthGoal = async () => {
        try {
            // Fetch current month goal
            const goalResponse = await fetch(`${baseUrl}/api/goals`);
            if (!goalResponse.ok) {
                throw new Error('Failed to fetch goals');
            }
            const goals = await goalResponse.json();
            const currentMonth = new Date().toISOString().slice(0, 7);
            const currentMonthGoal = goals.find(goal => goal.month.slice(0, 7) === currentMonth);
            setGoalData(currentMonthGoal);

            // Fetch exports data for the current month goal
            const exportsResponse = await fetch(`${baseUrl}/api/exports/goal/${currentMonthGoal._id}`);
            if (!exportsResponse.ok) {
                throw new Error('Failed to fetch exports for goal');
            }
            const exportsData = await exportsResponse.json();
            setExportsData(exportsData);

            let totalWeight = 0;
            let totalGross = 0;
            let totalVariable = 0;
            let totalFixed = 0;
            let totalSpent = 0;
            let totalExportExpenses = 0;
                exportsData.forEach(doc => { totalSpent += doc.lotsAll.usdSpentTotal;});
                exportsData.forEach(doc => { totalWeight += doc.lotsAll.kilogramTotal;});
                exportsData.forEach(doc => { totalGross += doc.estGrossCrg;});
                exportsData.forEach(doc => { totalVariable += doc.variableExpenses});
                exportsData.forEach(doc => { totalFixed += doc.fixedExpenses});
                exportsData.forEach(doc => { totalExportExpenses += doc.exportExpenses});

                setTotalWeightReal(totalWeight);
                setTotalGrossReal(totalGross);
                setTotalVariableExpensesReal(totalVariable);
                setTotalFixedExpensesReal(totalFixed);
                setTotalSpentReal(totalSpent);
                setTotalExportExpensesReal(totalExportExpenses);
    
            // Fetch expenses data based on the month and year of the displayed goal
            const expensesResponse = await fetch(`${baseUrl}/api/goals/expenses/monthly/${currentMonthGoal._id}`);
            if (!expensesResponse.ok) {
                throw new Error('Failed to fetch expenses data');
            }
            const expenses = await expensesResponse.json();
            setExpensesData(expenses);
            setTotalUsdSpentFixed(expenses[0].totalUsdSpentFixed);
            setTotalUsdSpentVariable(expenses[0].totalUsdSpentVariable);
            
            // Calculate total USD spent after setting expenses data
            let totalUsdSpent = 0;
            if (expenses) {
                expenses.forEach(expense => {
                    totalUsdSpent += expense.totalUsdSpent;
                });
            }
            setProgressValue(totalUsdSpent);
    
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    


    



    const fetchCrgData = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/crg`);
            if (!response.ok) {
                throw new Error('Failed to fetch CRG data');
            }
            const crgData = await response.json();
            setCrgData(crgData);
        } catch (error) {
            console.error('Error fetching CRG data:', error);
        }
    };

    useEffect(() => {
        fetchCurrentMonthGoal();
        fetchCrgData();
    }, [baseUrl]);

    useEffect(() => {
        // Create or update the pie chart when goalData and crgData change
        if (goalData && crgData) {
            createPieChart();
            createPieChartReal();
        }
    }, [goalData, crgData]);
    
    const sumKilograms = () => {
        if (!crgData) return 0;
        const currentMonth = new Date().getMonth();
        return crgData.ceramicsCrg.indCeramicsCrg.reduce((total, item) => {
            if (new Date(item.dateCrg).getMonth() === currentMonth) {
                return total + item.kilogramsCrg;
            }
            return total;
        }, 0);
    };

    const sumUsdValue = () => {
        if (!crgData) return 0;
        const currentMonth = new Date().getMonth();
        return crgData.ceramicsCrg.indCeramicsCrg.reduce((total, item) => {
            if (new Date(item.dateCrg).getMonth() === currentMonth) {
                return total + item.usdValueCrg;
            }
            return total;
        }, 0);
    };


    const handleSaveRealValues = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/goals/${goalData._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(realValues)
            });
            if (!response.ok) {
                throw new Error('Failed to save real values');
            }
    
            // Reset realValues state to initial state
            setRealValues({
                kilogramsReal: 0,
                grossProfitReal: 0,
                fixedExpensesReal: 0,
                variableExpensesReal: 0,
                exportExpensesReal: 0
            });
    
            // Refetch updated data
            fetchCurrentMonthGoal();
            fetchCrgData();
        } catch (error) {
            console.error('Error saving real values:', error);
        }
    };
    
    
const createPieChart = () => {
    const canvas = document.getElementById('pie-chart');
    if (!canvas) return;

    // Destroy existing chart instance if it exists
    if (window.pieChartInstance) {
        window.pieChartInstance.destroy();
    }

    // Set canvas width and height


    const ctx = canvas.getContext('2d');

    // Calculate values for the pie chart
    const totalProfit = goalData.grossProfit;
    const yellowPart = sumUsdValue() + (sumKilograms().toFixed(0) * 4.25.toFixed(0));

    const data = {
        labels: ['Predicted', 'Estimate'],
        datasets: [{
            data: [totalProfit, yellowPart],
            backgroundColor: ['black', '#ffea00']
        }]
    };

    window.pieChartInstance = new Chart(ctx, {
        type: 'pie',
        data: data,
        options: {
            responsive: true,
            maintainAspectRatio: false
        }
    });
};

const createPieChartReal = () => {
    const canvas = document.getElementById('pie-chart-real');
    if (!canvas) return;

    // Destroy existing chart instance if it exists
    if (window.pieChartInstanceReal) {
        window.pieChartInstanceReal.destroy();
    }

    // Set canvas width and height


    const ctx = canvas.getContext('2d');

    // Calculate values for the pie chart
    const totalProfit = goalData.grossProfit;
    const yellowPart = goalData.grossProfitReal;

    const data = {
        labels: ['Predicted', 'Real'],
        datasets: [{
            data: [totalProfit, yellowPart],
            backgroundColor: ['black', '#ffea00']
        }]
    };

    window.pieChartInstanceReal = new Chart(ctx, {
        type: 'pie',
        data: data,
        options: {
            responsive: true,
            maintainAspectRatio: false
        }
    });
};





    


    if (!goalData || !crgData || !expensesData) {
        return <p>Loading data...</p>;
    }
    const formatMonth = (dateString) => {
        const date = new Date(dateString);
        const options = { month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    return (
        <div className="current-goal">   

        <div className='goalChartMain'>    
        <div className='progress-bars'>
        <div className='progress-bar'>
                <span>
                    Entered Expenses ${progressValue} or {(progressValue / (goalData.fixedExpenses + goalData.variableExpenses) * 100).toFixed(2)}%
                </span>
                <div className='progress-container'>
                    <progress className={goalData.grossProfitReal / goalData.grossProfit * 100 > 99 ? 'progressBarItself red' : goalData.grossProfitReal / goalData.grossProfit * 100 > 70 ? 'progressBarItself yellow' : 'progressBarItself'} max={(goalData.fixedExpenses + goalData.variableExpenses)} value={progressValue}></progress>
                </div>
                </div>
            <div className='progress-bar'>
                <span>Est Gross: {((sumUsdValue() + (sumKilograms().toFixed(0) * 4.25.toFixed(0))) / goalData.grossProfit * 100).toFixed(2)}%</span>
                <div className='progress-container'>
                    <progress className={((sumUsdValue() + (sumKilograms().toFixed(0) * 4.25.toFixed(0))) / goalData.grossProfit * 100) > 99 ? 'progressBarItself red' : ((sumUsdValue() + (sumKilograms().toFixed(0) * 4.25.toFixed(0))) / goalData.grossProfit * 100) > 70 ? 'progressBarItself yellow' : 'progressBarItself'} max={goalData.grossProfit} value={sumUsdValue() + (sumKilograms().toFixed(0) * 4.25.toFixed(0))}></progress>
                </div>
            </div>
            <div className='progress-bar'>
                <span>Real Gross: {((totalGrossReal - totalSpentReal) / goalData.grossProfit * 100).toFixed(2)}%</span>
                <div className='progress-container'>
                    <progress className={(totalGrossReal - totalSpentReal) / goalData.grossProfit * 100 > 99 ? 'progressBarItself red' : goalData.grossProfitReal / goalData.grossProfit * 100 > 70 ? 'progressBarItself yellow' : 'progressBarItself'} max={goalData.grossProfit} value={goalData.grossProfitReal}></progress>
                </div>
            </div>
            <div className='progress-bar'>
                <span>Fixed Expenses: {(goalData.fixedExpensesReal / (goalData.fixedExpenses / goalData.kilograms * sumKilograms()).toFixed(0) * 100).toFixed(2)}%</span>
                <div className='progress-container'>
                    <progress className={goalData.fixedExpensesReal / (goalData.fixedExpenses / goalData.kilograms * sumKilograms()).toFixed(0) * 100 > 99 ? 'progressBarItself red' : goalData.fixedExpensesReal / (goalData.fixedExpenses / goalData.kilograms * sumKilograms()).toFixed(0) * 100 > 70 ? 'progressBarItself yellow' : 'progressBarItself'} max={(goalData.fixedExpenses / goalData.kilograms * sumKilograms()).toFixed(0)} value={goalData.fixedExpensesReal}></progress>
                </div>
            </div>
            <div className='progress-bar'>
                <span>Variable Expenses: {(goalData.variableExpensesReal / (goalData.variableExpenses / goalData.kilograms * sumKilograms()).toFixed(0) * 100).toFixed(2)}%</span>
                <div className='progress-container'>
                    <progress className={goalData.variableExpensesReal / (goalData.variableExpenses / goalData.kilograms * sumKilograms()).toFixed(0) * 100 > 99 ? 'progressBarItself red' : goalData.variableExpensesReal / (goalData.variableExpenses / goalData.kilograms * sumKilograms()).toFixed(0) * 100 > 70 ? 'progressBarItself yellow' : 'progressBarItself'} max={(goalData.variableExpenses / goalData.kilograms * sumKilograms()).toFixed(0)} value={goalData.variableExpensesReal}></progress>
                </div>
            </div>
        </div>

            </div> 

            <div className='goalChartMain'>  
            <div className='exportExpensesBoxMainGoal'>
            <h2 className='h1HeadersExportDetailsExpenses'>Goal {formatMonth(goalData?.month)} {((totalGrossReal - totalSpentReal) / totalSpentReal * 100).toFixed(0)}%</h2>
            <table className='table50Goal'>
               <thead className='table50'>
                 <tr className='table50Goal'>
                            <th className='table50Goal'>Type</th>
                            <th className='table50Goal'>Kilograms</th>
                            <th className='table50Goal'>Gross Profit</th>
                            <th className='table50Goal'>Fixed Exp</th>
                            <th className='table50Goal'>Variable Exp</th>
                            <th className='table50Goal'>Export Exp</th>
                            <th className='table50Goal'>Gross Margin</th>
                            <th className='table50Goal'>Net Profit</th>
                            <th className='table50Goal'>Net Margin</th>
                        </tr>
                    </thead>
                    <tbody className='table50Goal'>
                        <tr className='table50Goal'>
                            <td  className='inputExportDetails'>Projected</td>
                            <td  className='inputExportDetails'>{goalData.kilograms}</td>
                            <td  className='inputExportDetails'>$ {goalData.grossProfit}</td>
                            <td  className='inputExportDetails'>$ {goalData.fixedExpenses}</td>
                            <td  className='inputExportDetails'>$ {goalData.variableExpenses}</td>
                            <td  className='inputExportDetails'>$ {goalData.exportExpenses}</td>
                            <td  className='inputExportDetails'>$ {(goalData.grossProfit - goalData.exportExpenses) / goalData.kilograms}</td>
                            <td  className='inputExportDetails'>$ {goalData.grossProfit - goalData.fixedExpenses - goalData.variableExpenses - goalData.exportExpenses}</td>
                            <td  className='inputExportDetails'>$ {((goalData.grossProfit - goalData.fixedExpenses - goalData.variableExpenses - goalData.exportExpenses) / goalData.kilograms).toFixed(2)}</td>
                        </tr>

                        
                        <tr className='table50Goal'>
                            <td  className='inputExportDetails'>Real</td>
                            <td  className='inputExportDetails'>{totalWeightReal}</td>
                            <td  className='inputExportDetails'>$ {totalGrossReal - totalSpentReal}</td>
                            <td  className='inputExportDetails'>$ {totalUsdSpentFixed.toFixed(0)}</td>
                            <td  className='inputExportDetails'>$ {totalUsdSpentVariable}</td>
                            <td  className='inputExportDetails'>$ {totalExportExpensesReal}</td>
                            <td  className='inputExportDetails'>$ {((totalGrossReal - totalExportExpensesReal - totalSpentReal) / totalWeightReal).toFixed(2)}</td>
                            <td  className='inputExportDetails'>$ {(totalGrossReal - totalExportExpensesReal - totalSpentReal - totalUsdSpentFixed - totalUsdSpentVariable).toFixed(0)}</td>
                            <td  className='inputExportDetails'>$ {((totalGrossReal - totalExportExpensesReal -totalSpentReal - totalUsdSpentFixed - totalUsdSpentVariable) / totalWeightReal).toFixed(2)}</td>
                        </tr>

                        <tr className='table50Goal'>
                            <td  className='inputExportDetails'>Estimate</td>
                            <td  className='inputExportDetails'>{totalWeightReal}</td>
                            <td  className='inputExportDetails'>$ {totalGrossReal - totalSpentReal}</td>
                            <td  className='inputExportDetails'>$ {totalUsdSpentFixed.toFixed(0)}</td>
                            <td  className='inputExportDetails'>$ {totalUsdSpentVariable}</td>
                            <td  className='inputExportDetails'>$ {(totalWeightReal * 4.25).toFixed(0)}</td>
                            <td  className='inputExportDetails'>$ {((totalGrossReal - totalWeightReal * 4.25 - totalSpentReal) / totalWeightReal).toFixed(2)}</td>
                            <td  className='inputExportDetails'>$ {(totalGrossReal - totalWeightReal * 4.25 - totalSpentReal - totalUsdSpentFixed - totalUsdSpentVariable).toFixed(0)}</td>
                            <td  className='inputExportDetails'>$ {((totalGrossReal - totalWeightReal * 4.25 -totalSpentReal - totalUsdSpentFixed - totalUsdSpentVariable) / totalWeightReal).toFixed(2)}</td>
                        </tr>


                        <tr className='table50Goal'>
                            <td  className='inputExportDetails'>Month</td>
                            <td  className='inputExportDetails'>{sumKilograms().toFixed(0)}</td>
                            <td  className='inputExportDetails'>$ {(sumUsdValue() + (sumKilograms().toFixed(2) * 4.25.toFixed(2))).toFixed(0)}</td>
                            <td  className='inputExportDetails'>$ {totalUsdSpentFixed.toFixed(0)}</td>
                            <td  className='inputExportDetails'>$ {totalUsdSpentVariable}</td>
                            <td  className='inputExportDetails'>$ {(sumKilograms().toFixed(2) * 4.25.toFixed(2)).toFixed(0)}</td>
                            <td  className='inputExportDetails'>$ {((sumUsdValue() + (sumKilograms().toFixed(2) * 4.25.toFixed(2)) - sumKilograms().toFixed(2) * 4.25.toFixed(2)) / sumKilograms().toFixed(2)).toFixed(2)}</td>
                            <td  className='inputExportDetails'>$ {((sumUsdValue() + (sumKilograms().toFixed(2) * 4.25.toFixed(2))).toFixed(0) - sumKilograms() * 4.25 - totalUsdSpentFixed - totalUsdSpentVariable).toFixed(0)}</td>
                            <td  className='inputExportDetails'>$ {(((sumUsdValue() + (sumKilograms().toFixed(2) * 4.25.toFixed(2))).toFixed(0) - sumKilograms() * 4.25 - totalUsdSpentFixed - totalUsdSpentVariable) / sumKilograms()).toFixed(2)}</td>
                        </tr>
                        <tr className='table50Goal'>
                            <td  className='inputExportDetails'>Manual</td>
                            <td  className='inputExportDetails'>{goalData.kilogramsReal}</td>
                            <td className='inputExportDetails'>$ {goalData.grossProfitReal}</td>
                            <td  className='inputExportDetails'>$ {goalData.fixedExpensesReal.toFixed(0)}</td>
                            <td  className='inputExportDetails'>$ {goalData.variableExpensesReal}</td>
                            <td  className='inputExportDetails'>$ {(goalData.kilogramsReal * 4.25.toFixed(2)).toFixed(0)}</td>
                            <td  className='inputExportDetails'>$ {((goalData.grossProfitReal - goalData.kilogramsReal * 4.25.toFixed(2)) / goalData.kilogramsReal).toFixed(2)}</td>
                            <td  className='inputExportDetails'>$ {(goalData.grossProfitReal - goalData.fixedExpensesReal - goalData.variableExpensesReal - goalData.kilogramsReal * 4.25).toFixed(0)}</td>
                            <td  className='inputExportDetails'>$ {((goalData.grossProfitReal - goalData.fixedExpensesReal - goalData.variableExpensesReal - goalData.kilogramsReal * 4.25.toFixed(2)) / goalData.kilogramsReal).toFixed(2) }</td>
                        </tr>
                        <tr className='table50Goal'>
    <td  className='inputExportDetailsGoal'>Manual</td>
    <td className='inputExportDetailsGoal'>
        <input className='inputExportDetailsGoal' 
            type="number" 
            value={realValues.kilogramsReal} 
            onChange={e => setRealValues({...realValues, kilogramsReal: e.target.value})}
        />
    </td>
    <td className='inputExportDetailsGoal'>
        <input  className='inputExportDetailsGoal'
            type="number" 
            value={realValues.grossProfitReal} 
            onChange={e => setRealValues({...realValues, grossProfitReal: e.target.value})}
        />
    </td>
    <td className='inputExportDetailsGoal'>
        <input  className='inputExportDetailsGoal'
            type="number" 
            value={realValues.fixedExpensesReal} 
            onChange={e => setRealValues({...realValues, fixedExpensesReal: e.target.value})}
        />
    </td>
    <td className='inputExportDetailsGoal'>
        <input className='inputExportDetailsGoal'
            type="number" 
            value={realValues.variableExpensesReal} 
            onChange={e => setRealValues({...realValues, variableExpensesReal: e.target.value})}
        />
    </td>
    <td className='inputExportDetailsGoal'>
        <input className='inputExportDetailsGoal'
            type="number" 
            value={realValues.exportExpensesReal} 
            onChange={e => setRealValues({...realValues, exportExpensesReal: e.target.value})}
        />
    </td>
    <td className='inputExportDetailsGoal'>
        {/* Assuming the Projected Margin value is calculated based on the Real values */}
        $ {((realValues.grossProfitReal - realValues.exportExpensesReal) / realValues.kilogramsReal).toFixed(2)}
    </td>
    <td className='inputExportDetailsGoal'>
        {/* Assuming the Projected Net value is calculated based on the Real values */}
        $ {realValues.grossProfitReal - realValues.fixedExpensesReal - realValues.variableExpensesReal - realValues.exportExpensesReal}
    </td>
    <td className='inputExportDetailsGoal'>
        {/* Assuming the Projected Margin value is calculated based on the Real values */}
        $ {((realValues.grossProfitReal - realValues.fixedExpensesReal - realValues.variableExpensesReal - realValues.exportExpensesReal) / realValues.kilogramsReal).toFixed(2)}
    </td>
    </tr>
                </tbody>
                    </table>
            <button className='expenseAllButton' onClick={handleSaveRealValues}>Save Manual Data</button>
            </div>
            </div>
        </div>
    );
};

export default CurrentGoal;
