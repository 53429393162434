import React from 'react';
import { useParams } from 'react-router-dom';
import ImageUploader from './images';

function AdminSingle() {
  const { documentId } = useParams(); 

  return (
    <div>
      <h1>Admin Main Component</h1>
      <p>Document ID: {documentId}</p>
      <ImageUploader/>
    </div>
  );
}

export default AdminSingle;
