import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../userAuth'; 
import { useLocation, useNavigate } from 'react-router-dom';
import './calendarWarehouse.css';

const ScheduleSalesView = () => {
  const { isLoggedIn } = useAuth();
  const [contactData, setContactData] = useState([]);
  const location = useLocation();
  const userInfo = location.state?.userInfo || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/contacts`);
        setContactData(response.data);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchContacts();
  }, [baseUrl]);

  // Get today's date and tomorrow's date
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  // Filter contacts for today and tomorrow with typeOfContact === 'Visit' and user name matching userInfo.name
  const filteredContactsToday = contactData.filter((contact) => {
    const contactDate = new Date(contact.nextContact);
    return (
      contactDate.toISOString().split('T')[0] === today.toISOString().split('T')[0] &&
      contact.typeOfContact === 'Visit' &&
      contact.user.name === userInfo.name
    );
  });

  const filteredContactsTomorrow = contactData.filter((contact) => {
    const contactDate = new Date(contact.nextContact);
    return (
      contactDate.toISOString().split('T')[0] === tomorrow.toISOString().split('T')[0] &&
      contact.typeOfContact === 'Visit' &&
      contact.user.name === userInfo.name
    );
  });

  // Helper function to get the last note of a contact
  const getLastNote = (contact) => {
    if (contact.notes && contact.notes.length > 0) {
      const sortedNotes = contact.notes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      return sortedNotes[0].content;
    }
    return 'No notes available';
  };

  return (
    <>
      {isLoggedIn && (
        <div className='warehouse-container'>
          <div className='warehouse-box-day'>
            <h2>Today - {today.toDateString()}</h2>
            {filteredContactsToday.map((contact) => (
              <div key={contact._id}>
                <span className="warehouse-material-type">{contact.user.name}</span> - {''}
                <span className="warehouse-contact-name">{contact.name}</span>
                <p className="warehouse-contact-note">Last note: {getLastNote(contact)}</p>
              </div>
            ))}
          </div>

          <div className='warehouse-box-day'>
            <h2>Tomorrow - {tomorrow.toDateString()}</h2>
            {filteredContactsTomorrow.map((contact) => (
              <div key={contact._id}>
                <span className="warehouse-material-type">{contact.user.name}</span> - {''}
                <span className="warehouse-contact-name">{contact.name}</span>
                <p className="warehouse-contact-note">Last note: {getLastNote(contact)}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ScheduleSalesView;
