import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../userAuth';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './debtbreakdown.css';

const DebtBreakdown = () => {
  const { isLoggedIn } = useAuth();
  const [debtCrg, setDebtCrg] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  useEffect(() => {
    // Fetch debt data from your API endpoint
    axios.get(`${baseUrl}/api/crg/debt`)
      .then(response => {
        // Sort the data by name
        const sortedData = response.data.sort((a, b) => a.contactCrg.name.localeCompare(b.contactCrg.name));
        setDebtCrg(sortedData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching debt data:', error);
        setLoading(false);
      });
  }, [baseUrl]);

  const handleContactClick = (contactId) => {
    // Use the navigate function to navigate to the ContactDetails component
    navigate(`/contact-details/${contactId}`);
  };

return (
  <div className='debt-breakdown-container'>
    {isLoggedIn ? (
      <>
        <h1>Debt Breakdown</h1>
        {loading ? (
          <p>Loading data...</p>
        ) : (
          <div className='debt-entries'>
            <table className="debt-table">
              <thead>
                <tr>
                  <th data-label="Contact Name">Contact Name</th>
                  <th data-label="Debt Amount (USD)">Debt Amount (USD)</th>
                  <th data-label="Edit">Go to</th>
                </tr>
              </thead>
              <tbody>
                {debtCrg.map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.contactCrg.name}</td>
                    <td>${entry.usdValueCrg}</td>
                    <td>
                      <FontAwesomeIcon
                        className='visitDebtIcon'
                        icon={faArrowRight}
                        onClick={() => handleContactClick(entry.contactCrg.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    ) : (
      <p>Please log in to view debt breakdown.</p>
    )}
  </div>
);

};

export default DebtBreakdown;
