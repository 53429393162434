import React, { useState, useEffect } from 'react';
import './ppmcalc.css';

const PriceCalculator = () => {
  const [finalResult, setFinalResult] = useState(null);
  const [ptPrice, setPtPrice] = useState('');
  const [ptPpm, setPtPpm] = useState('');
  const [ptCalculatedPpm, setPtCalculatedPpm] = useState(null);
  const [showFinalResult, setShowFinalResult] = useState(false);
  const [pdPrice, setPdPrice] = useState('');
  const [pdPpm, setPdPpm] = useState('');
  const [pdCalculatedPpm, setPdCalculatedPpm] = useState(null);
  const [rhPrice, setRhPrice] = useState('');
  const [rhPpm, setRhPpm] = useState('');
  const [rhCalculatedPpm, setRhCalculatedPpm] = useState(null);
  const [totalSum, setTotalSum] = useState(null);
  const [ptSecondValue, setPtSecondValue] = useState(null);
  const [pdSecondValue, setPdSecondValue] = useState(null);
  const [rhSecondValue, setRhSecondValue] = useState(null);
  const [totalSecondValues, setTotalSecondValues] = useState(null);

  const calculateSecondValue = (element, ppm) => {
    switch (element) {
      case 'PT':
        return ppm ? (parseFloat(ppm) * 0.9).toFixed(2) : null;
      case 'PD':
        return ppm ? (parseFloat(ppm) * 0.85).toFixed(2) : null;
      case 'RH':
        return ppm ? (parseFloat(ppm) * 0.9).toFixed(2) : null;
      default:
        return null;
    }
  };

  const calculateTotalSecondValues = () => {
    const ptSecond = ptSecondValue ? parseFloat(ptSecondValue) : 0;
    const pdSecond = pdSecondValue ? parseFloat(pdSecondValue) : 0;
    const rhSecond = rhSecondValue ? parseFloat(rhSecondValue) : 0;

    return (ptSecond + pdSecond + rhSecond) * 0.85;
  };

  useEffect(() => {
    setTotalSecondValues(calculateTotalSecondValues());
  }, [ptSecondValue, pdSecondValue, rhSecondValue]);

  useEffect(() => {
    setPtSecondValue(calculateSecondValue('PT', ptPpm));
  }, [ptPpm]);

  useEffect(() => {
    setPdSecondValue(calculateSecondValue('PD', pdPpm));
  }, [pdPpm]);

  useEffect(() => {
    setRhSecondValue(calculateSecondValue('RH', rhPpm));
  }, [rhPpm]);
  

  const handleButtonClick = () => {
    setShowFinalResult(!showFinalResult);
  };

  const handleInputChange = (e, element, inputType) => {
    const value = e.target.value;
    if (element === 'PT') {
      if (inputType === 'price') {
        setPtPrice(value);
      } else if (inputType === 'ppm') {
        setPtPpm(value);
      }
    } else if (element === 'PD') {
      if (inputType === 'price') {
        setPdPrice(value);
      } else if (inputType === 'ppm') {
        setPdPpm(value);
      }
    } else if (element === 'RH') {
      if (inputType === 'price') {
        setRhPrice(value);
      } else if (inputType === 'ppm') {
        setRhPpm(value);
      }
    }
  };

  // Function to calculate PPM
  const calculatePpm = (element, price, ppm, setCalculatedPpm) => {
    if (price && ppm) {
      const calculatedPpm = (parseFloat(ppm) / 1000) * parseFloat(price);
      setCalculatedPpm(calculatedPpm.toFixed(2));
    } else {
      setCalculatedPpm(null);
    }
  };

  // Effect to recalculate PPM whenever input values change
  useEffect(() => {
    calculatePpm('PT', ptPrice, ptPpm, setPtCalculatedPpm);
  }, [ptPrice, ptPpm]);

  useEffect(() => {
    calculatePpm('PD', pdPrice, pdPpm, setPdCalculatedPpm);
  }, [pdPrice, pdPpm]);

  useEffect(() => {
    calculatePpm('RH', rhPrice, rhPpm, setRhCalculatedPpm);
  }, [rhPrice, rhPpm]);

  // Effect to calculate and update the total sum whenever any of the individual sums change
  useEffect(() => {
    const ptSum = ptCalculatedPpm ? parseFloat(ptCalculatedPpm) : 0;
    const pdSum = pdCalculatedPpm ? parseFloat(pdCalculatedPpm) : 0;
    const rhSum = rhCalculatedPpm ? parseFloat(rhCalculatedPpm) : 0;

    setTotalSum((ptSum + pdSum + rhSum).toFixed(2));
  }, [ptCalculatedPpm, pdCalculatedPpm, rhCalculatedPpm]);

  useEffect(() => {
    if (totalSum !== null) {
      // Deduct 5 only if the total sum is greater than 5
      const result = totalSum > 5 ? (parseFloat(totalSum) * 0.75 - 5).toFixed(2) : totalSum;
      setFinalResult(result);
    } else {
      setFinalResult(null);
    }
  }, [totalSum]);

  return (
    <div className='calculatorMain'>
    <div className='calculatorTitle'>
      Vanta PPM's
     </div>
      <div className='calculatorSecond'>

        <div className='PT'>
          PT
          <input
            type='text'
            placeholder='Enter USD price / gram'
            value={ptPrice}
            onChange={(e) => handleInputChange(e, 'PT', 'price')}
          />
          <input
            type='text'
            placeholder='Enter PPM'
            value={ptPpm}
            onChange={(e) => handleInputChange(e, 'PT', 'ppm')}
          />
        </div>
        <div className='PD'>
          PD
          <input
            type='text'
            placeholder='Enter USD price / gram'
            value={pdPrice}
            onChange={(e) => handleInputChange(e, 'PD', 'price')}
          />
          <input
            type='text'
            placeholder='Enter PPM'
            value={pdPpm}
            onChange={(e) => handleInputChange(e, 'PD', 'ppm')}
          />
        </div>
        <div className='RH'>
          RH
          <input
            type='text'
            placeholder='Enter USD price / gram'
            value={rhPrice}
            onChange={(e) => handleInputChange(e, 'RH', 'price')}
          />
          <input
            type='text'
            placeholder='Enter PPM'
            value={rhPpm}
            onChange={(e) => handleInputChange(e, 'RH', 'ppm')}
          />
        </div>
      </div>
      <div className='Charges'>
        <br />
        {showFinalResult && (
          <>
            Fair Value: ${finalResult ? finalResult : 'N/A'}
            <br />
          </>
        )}
        <button onClick={handleButtonClick}>
          {showFinalResult ? 'Hide Final Result' : 'Show Final Result'}
        </button>
      </div>
    </div>
  );
};

export default PriceCalculator;
