import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../userAuth';
import { useParams, useNavigate } from 'react-router-dom';

const MonthlyExpense = () => {
    const { isLoggedIn } = useAuth();
    const title = localStorage.title;
    const navigate = useNavigate();
    useEffect(() => {
      if (!isLoggedIn) {
        navigate('/login');
      }
    }, [isLoggedIn, navigate]);
    const { id } = useParams();
    const [expense, setExpense] = useState(null);
    const [debt, setDebt] = useState(null);
    const [newUsdInAll, setNewUsdInAll] = useState({ usdIn: '' });
    const [newUsdExport, setNewUsdExport] = useState({ usdSpent: '' });
    const [newOpenDebt, setNewOpenDebt] = useState({ newOpenDebt: '' });
    const [newOpenCash, setNewOpenCash] = useState({ newOpenCash: '' });
    const [newAccountingPurchases, setNewAccountingPurchases] = useState({ newAccountingPurhcases: '' });
    const [exportLoads, setExportLoads] = useState([]);
    const [selectedExportLoad, setSelectedExportLoad] = useState('');
    const [newFixedExpense, setNewFixedExpense] = useState({ type: '', number: '', spentUsd: '' });
    const [newVariableExpense, setNewVariableExpense] = useState({ type: '', number: '', spentUsd: '' });
    const isLocal = window.location.hostname === 'localhost';
    const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';

    const fetchExpense = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/expenses/${id}`);
            setExpense(response.data);
        } catch (error) {
            console.error('Error fetching expense:', error);
        }
    };

    const fetchDebt = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/crg`);
            setDebt(response.data.debtCrgTotalUsd);
        } catch (error) {
            console.error('Error fetching expense:', error);
        }
    };

    const fetchExportLoads = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/exports/lastFour`);
            setExportLoads(response.data);
        } catch (error) {
            console.error('Error fetching export loads:', error);
        }
    };

    useEffect(() => {
        fetchExpense();
        fetchDebt();
        fetchExportLoads();
    }, [id, baseUrl]);

    const handleCreateFixedExpense = async () => {
        try {
            const selectedLoad = exportLoads.find(load => load._id === selectedExportLoad);
            const data = { ...newFixedExpense, exportLoadId: selectedExportLoad, load: selectedLoad.load };
            await axios.post(`${baseUrl}/api/expenses/${id}/fixedExpenses`, data);
            setNewFixedExpense({ type: '', spentUsd: '', load: '' }); // Clear form fields
            setSelectedExportLoad(''); // Clear selected export load
            fetchExpense(); // Fetch updated expense data
        } catch (error) {
            console.error('Error creating fixed expense:', error);
        }
    };
    
    const handleCreateVariableExpense = async () => {
        try {
            const selectedLoad = exportLoads.find(load => load._id === selectedExportLoad);
            const data = { ...newVariableExpense, exportLoadId: selectedExportLoad, load: selectedLoad.load };
            await axios.post(`${baseUrl}/api/expenses/${id}/variableExpenses`, data);
            setNewVariableExpense({ type: '', spentUsd: '' }); // Clear form fields
            setSelectedExportLoad(''); // Clear selected export load
            fetchExpense(); // Fetch updated expense data
        } catch (error) {
            console.error('Error creating variable expense:', error);
        }
    };

    const handleCreateMoneyIn = async () => {
        try {
            const selectedLoad = exportLoads.find(load => load._id === selectedExportLoad);
            const data = { ...newUsdInAll, exportLoadId: selectedExportLoad, load: selectedLoad.load };
            await axios.post(`${baseUrl}/api/expenses/${id}/moneyIn`, data);
            setNewUsdInAll({ usdIn: '' }); // Clear form fields
            setSelectedExportLoad(''); // Clear selected export load
            fetchExpense(); // Fetch updated expense data
        } catch (error) {
            console.error('Error creating variable expense:', error);
        }
    };

    const handleCreateExportExpense = async () => {
        try {
            const selectedLoad = exportLoads.find(load => load._id === selectedExportLoad);
            const data = { ...newUsdExport, exportLoadId: selectedExportLoad, load: selectedLoad.load };
            await axios.post(`${baseUrl}/api/expenses/${id}/exportExpense`, data);
            setNewUsdExport({ usdSpent: '' }); // Clear form fields
            setSelectedExportLoad(''); // Clear selected export load
            fetchExpense(); // Fetch updated expense data
        } catch (error) {
            console.error('Error creating variable expense:', error);
        }
    };

    const handleCreateOpenCash = async () => {
        try {
            const data = { ...newOpenCash };
            await axios.post(`${baseUrl}/api/expenses/${id}/openCash`, data);
            setNewOpenCash({ openCash: '' }); // Clear form fields
            fetchExpense(); // Fetch updated expense data
        } catch (error) {
            console.error('Error creating variable expense:', error);
        }
    };

    const handleCreateOpenDebt = async () => {
        try {
            const data = { ...newOpenDebt };
            await axios.post(`${baseUrl}/api/expenses/${id}/openDebt`, data);
            setNewOpenDebt({ openDebt: '' }); // Clear form fields
            fetchExpense(); // Fetch updated expense data
        } catch (error) {
            console.error('Error creating variable expense:', error);
        }
    };
    
    const handleCreateAccountingPurchases = async () => {
        try {
            const data = { ...newAccountingPurchases };
            await axios.post(`${baseUrl}/api/expenses/${id}/accountingPurchases`, data);
            setNewAccountingPurchases({ accountingPurchases: '' }); // Clear form fields
            fetchExpense(); // Fetch updated expense data
        } catch (error) {
            console.error('Error creating variable expense:', error);
        }
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Month starts from 0
        const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of year
    
        // Pad single digits with leading zero
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;
    
        return `${formattedDay}/${formattedMonth}/${year}`;
    }
    

    if (!expense) {
        return <div>Loading...</div>;
    }

    return (
        <div className="exports-second">
      {isLoggedIn && title === 'Admin' && (
    <>
            <h1 className='h1HeadersExportDetailsExpenses'>{new Date(expense.month).toLocaleString('default', { month: 'long', year: 'numeric' })}</h1>
            <div className='exports-third-row'>
            <div className='export-unused-row'>
            <div className='export-unused-expenses'>Total ${expense.totalUsdSpent}</div>
            </div>
            <div className='export-unused-row'>
            <div className='export-unused-expenses'>Fixed ${expense.totalUsdSpentFixed}</div>
            </div>
            <div className='export-unused-row'>
            <div className='export-unused-expenses'>Variable ${expense.totalUsdSpentVariable}</div>
            </div>
            <div className='export-unused-row'>
            <div className='export-unused-expenses'>Debt Now ${debt}</div>
            </div>
            <div className='export-unused-row'>
            <div className='export-unused-expenses'>Debt Open ${expense.openDebt}</div>
            </div>
            <div className='export-unused-row'>
            <div className='export-unused-expenses'>Open Cash ${expense.openCash}</div>
            </div>
            </div>



   
            {expense.openCash < 1 && (
            <div className='exportExpensesBoxRowResponsive'>
            <form className='formExpensesRowResponsive' onSubmit={handleCreateOpenCash}>
            <input className='inputTask'
                    type="text"
                    name="description"
                    value={expense.newOpenCash}
                    onChange={(e) => setNewOpenCash({ openCash: e.target.value })}
                    placeholder="Opening Cash"
                    />
                <button className='expenseAllButtonOpenCash' type="submit">Submit</button>
            </form>
            </div>
        )}

        {expense.openDebt < 1 && (
            <div className='exportExpensesBoxRowResponsive'>
            <form className='formExpensesRowResponsive' onSubmit={handleCreateOpenDebt}>
            <input className='inputTask'
                    type="text"
                    name="description"
                    value={expense.newOpenDebt}
                    onChange={(e) => setNewOpenDebt({ openDebt: e.target.value })}
                    placeholder="Opening Debt"
                    />
                <button className='expenseAllButtonOpenCash' type="submit">Submit</button>
            </form>
            </div>
        )}
     

            <div className='exportDetailsTopRowExpenses'>
            <div className='exportExpensesBox'>
            <h2 className='h1HeadersExportDetailsExpenses'>Money In</h2>
            <form onSubmit={handleCreateMoneyIn}>
                <input className='inputTaskExpenses' type="text" value={newUsdInAll.usdIn} onChange={(e) => setNewUsdInAll({ ...newUsdInAll, usdIn: e.target.value })} placeholder="Amount Received" />
                <select className='selectTaskExpenses' value={selectedExportLoad} onChange={(e) => setSelectedExportLoad(e.target.value)}>
                    <option value="">Select an export load</option>
                    {exportLoads.map((load) => (
                        <option key={load._id} value={load._id}>{load.load}</option>
                    ))}
                </select>
                <button className='expenseAllButton' type="submit">Add Money In</button>
            </form>
            </div>

            {expense.totalUsdInAll > 1 && (
            <div className='exportExpensesBox'>
            <h2 className='h1HeadersExportDetailsExpenses'>Money In</h2>
            <table className='table50'>
                    <thead className='table50'>
                        <tr className='table50'>
                            <th className='table50header'>Date</th>
                            <th className='table50header'>Amount</th>
                        </tr>
                    </thead>
                    <tbody className='table50'>
                        {expense.usdInAll.map((item, index) => (
                            <tr className='table50' key={index}>
                                <td className='inputExportDetailsDate'>{formatDate(item.date)}</td>
                                <td className='inputExportDetails'>$ {item.usdIn}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
    
                </div>
                )}
            </div>


            <div className='exportDetailsTopRowExpenses'>
            <div className='exportExpensesBox'>
            <h2 className='h1HeadersExportDetailsExpenses'>Add Export Expense</h2>
            <form onSubmit={handleCreateExportExpense}>
                <input className='inputTaskExpenses' type="text" value={newUsdExport.usdSpent} onChange={(e) => setNewUsdExport({ ...newUsdExport, usdSpent: e.target.value })} placeholder="Amount Spent" />
                <select className='selectTaskExpenses' value={selectedExportLoad} onChange={(e) => setSelectedExportLoad(e.target.value)}>
                    <option value="">Select an export load</option>
                    {exportLoads.map((load) => (
                        <option key={load._id} value={load._id}>{load.load}</option>
                    ))}
                </select>
                <button className='expenseAllButton' type="submit">Add Money In</button>
            </form>
            </div>
            <div className='exportExpensesBox'>
            <h2 className='h1HeadersExportDetailsExpenses'>Export Expenses</h2>
            <table className='table50'>
                    <thead className='table50'>
                        <tr className='table50'>
                            <th className='table50header'>Date</th>
                            <th className='table50header'>Load</th>
                            <th className='table50header'>Amount</th>
                        </tr>
                    </thead>
                    <tbody className='table50'>
                        {expense.usdExport.map((item, index) => (
                            <tr className='table50' key={index}>
                                <td className='inputExportDetailsDate'>{formatDate(item.date)}</td>
                                <td className='inputExportDetailsDate'>{item.exportLoad.load}</td>
                                <td className='inputExportDetails'>$ {item.usdSpent}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                </div>
            </div>





            <div className='exportDetailsTopRowExpenses'>
            <div className='exportExpensesBox'>
            <h2 className='h1HeadersExportDetailsExpenses'>Add Fixed Expense</h2>
            <form onSubmit={handleCreateFixedExpense}>
                <input className='inputTaskExpenses' type="text" value={newFixedExpense.type} onChange={(e) => setNewFixedExpense({ ...newFixedExpense, type: e.target.value })} placeholder="Type" />
                <input className='inputTaskExpenses' type="text" value={newFixedExpense.spentUsd} onChange={(e) => setNewFixedExpense({ ...newFixedExpense, spentUsd: e.target.value })} placeholder="Spent USD" />
                <select className='selectTaskExpenses' value={selectedExportLoad} onChange={(e) => setSelectedExportLoad(e.target.value)}>
                    <option value="">Select an export load</option>
                    {exportLoads.map((load) => (
                        <option key={load._id} value={load._id}>{load.load}</option>
                    ))}
                </select>
                <button className='expenseAllButton' type="submit">Add Fixed Expense</button>
            </form>
            </div>

            <div className='exportExpensesBox'>
            <h2 className='h1HeadersExportDetailsExpenses'>Add Variable Expense</h2>
            <form onSubmit={handleCreateVariableExpense}>
                <input className='inputTaskExpenses' type="text" value={newVariableExpense.type} onChange={(e) => setNewVariableExpense({ ...newVariableExpense, type: e.target.value })} placeholder="Type" />
                <input className='inputTaskExpenses' type="text" value={newVariableExpense.spentUsd} onChange={(e) => setNewVariableExpense({ ...newVariableExpense, spentUsd: e.target.value })} placeholder="Spent USD" />
                <select className='selectTaskExpenses' value={selectedExportLoad} onChange={(e) => setSelectedExportLoad(e.target.value)}>
                    <option value="">Select an export load</option>
                    {exportLoads.map((load) => (
                        <option key={load._id} value={load._id}>{load.load}</option>
                    ))}
                </select>
                <button className='expenseAllButton' type="submit">Add Variable Expense</button>
            </form>
            </div>
            </div>


            <div className='exportDetailsTopRowExpenses'>
            <div className='exportExpensesBox'>
            <h2 className='h1HeadersExportDetailsExpenses'>Fixed Expenses</h2>
            <table className='table50'>
                    <thead className='table50'>
                        <tr className='table50'>
                            <th className='table50header'>Date</th>
                            <th className='table50header'>Type</th>
                            <th className='table50header'>Load</th>
                            <th className='table50header'>Spent</th>
                        </tr>
                    </thead>
                    <tbody className='table50'>
                        {expense.fixedExpenses.map((item, index) => (
                            <tr className='table50' key={index}>
                                <td className='inputExportDetailsDate'>{formatDate(item.created)}</td>
                                <td className='inputExportDetailsType'>{item.type}</td>
                                <td className='inputExportDetailsDate'>{item.exportLoad.load}</td>
                                <td className='inputExportDetails'>$ {item.spentUsd}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                </div>
            <div className='exportExpensesBox'>
            <h2 className='h1HeadersExportDetailsExpenses'>Variable Expenses</h2>
            <table className='table50'>
                    <thead className='table50'>
                        <tr className='table50'>
                            <th className='table50header'>Date</th>
                            <th className='table50header'>Type</th>
                            <th className='table50header'>Load</th>
                            <th className='table50header'>Spent</th>
                        </tr>
                    </thead>
                    <tbody className='table50'>
                        {expense.variableExpenses.map((item, index) => (
                            <tr className='table50' key={index}>
                                <td className='inputExportDetailsDate'>{formatDate(item.created)}</td>
                                <td className='inputExportDetailsType'>{item.type}</td>
                                <td className='inputExportDetailsDate'>{item.exportLoad.load}</td>
                                <td className='inputExportDetails'>$ {item.spentUsd}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            </div>
            </>
            )}
        </div>
    );
};

export default MonthlyExpense;
