import React from 'react';
import './info.css';

const Info = () => {


  return (
    <div className="main-container">
      <h2>Established in 2013, we are one of the Middle East’s leading waste collection, recycling, and management companies.</h2>
      <p>Being in the industry for more than 10 years, we have built experience and knowledge in the field enabling us to provide a wide range of services to our clients.</p>

    </div>
  );
};

export default Info;

