import React, { useEffect, useState } from 'react';
import './prmetals.css';

const PreciousMetalsPrices = () => {
  const [prices, setPrices] = useState(null);
  const [error, setError] = useState(null);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';

  useEffect(() => {
    fetchPreciousMetalsPrices();
          // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPreciousMetalsPrices = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/charges`);
      const data = await response.json();

      if (response.ok) {
        const charges = data[0]; // Assuming there's only one charges document
        setPrices({
          silverPrice: charges.silverPrice,
          copperPrice: charges.copperPrice,
          goldPrice: charges.goldPrice,
          palladiumPrice: charges.palladiumPrice,
        });
      } else {
        setError('Failed to fetch prices');
      }
    } catch (error) {
      setError('Failed to fetch prices');
    }
  };

  return (
    <div>
      {error && <p>{error}</p>}
      {prices && (
        <div>
        <div className='pageheader'>Market Data</div>
        <div className='prmetalsmain'>
        <div className='simpletext'>Gold: $ {prices.goldPrice}</div>
          <div className='simpletext'>Silver: $ {prices.silverPrice}</div>
          <div className='simpletext'>Palladium: $ {prices.palladiumPrice}</div>
          <div className='simpletext'>Copper: $ {prices.copperPrice}</div>
        </div>
        </div>
      )}
    </div>
  );
};

export default PreciousMetalsPrices;
