import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import { useAuth } from '../../../userAuth';

const CrgReportNewOthers = () => {
    const { isLoggedIn } = useAuth();
    const title = localStorage.title;
    const [month, setMonth] = useState(null);
    const [reports, setReports] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
      if (!isLoggedIn) {
        navigate('/login');
      }
    }, [isLoggedIn, navigate]);
    const isLocal = window.location.hostname === 'localhost';
    const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';

    useEffect(() => {
        fetchReports();
        fetchLastReportMonth();
    }, []);

    const fetchReports = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/reports`);
            setReports(response.data);
        } catch (error) {
            console.error('Error fetching reports:', error);
        }
    };

    const fetchLastReportMonth = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/reports/last`);
            if (response.data) {
                // Get the last report month and add one month to it
                const lastMonth = new Date(response.data.month);
                lastMonth.setMonth(lastMonth.getMonth() + 1);
                setMonth(lastMonth);
            } else {
                // If no reports exist, set the current month
                setMonth(new Date());
            }
        } catch (error) {
            console.error('Error fetching last report month:', error);
        }
    };

    const handleReportClick = (reportId) => {
        navigate(`/crg-monthly-report-view/${reportId}/ajsdkl;gjreaigjf;shkjfa'dfdskjfas'fjdkwrgfh5fhfdg523fg2j`);
    };

    return (
        <div className="tasks-container">
            {isLoggedIn && title === 'Admin' && (
    <>
            <div className="calendar">
                {reports.map((report, index) => (
                    <div key={index} onClick={() => handleReportClick(report._id)} className="expense-item">
                        <p>{new Date(report.month).toLocaleString('default', { month: 'long', year: 'numeric' })}</p>
                        {/* You can add more details from the report object if needed */}
                    </div>
                ))}
            </div>
                </>
            )}
        </div>
    );
};

export default CrgReportNewOthers;
