import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../userAuth';
import './export.css';

const ExportMain = () => {
    const { isLoggedIn } = useAuth();
    const title = localStorage.title;
    const [exports, setExports] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
      if (!isLoggedIn) {
        navigate('/login');
      }
    }, [isLoggedIn, navigate]);
    const isLocal = window.location.hostname === 'localhost';
    const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';

    useEffect(() => {
        const fetchExports = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/exports`); // /api/exports/lastFour
                setExports(response.data);
            } catch (error) {
                console.error('Error fetching export documents:', error);
            }
        };

        fetchExports();
    }, []);

    // Function to format date to month and year
    const formatMonthYear = (dateString) => {
        const date = new Date(dateString);
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        return `${month} ${year}`;
    };

    const handleExportLoadClick = (id) => {
        navigate(`/crg-export-form/laskdjfewaioptjdfkl;gjfsg;jdsfksgsl'dajfkl,mds;m;`);
    };

    const handleExportViewLoadClick = (id) => {
        navigate(`/crg-export-load-details/asd6ar5e459843y4tr65j4hk6yu5y4usfd5g1a+f56ds4f+af/${id}`);
    };

  

    return (
        <div className="tasks-container">
                                {isLoggedIn && title === 'Admin' && (
    <>
            <h1 className='h1HeadersExportDetails'>Exports</h1>
            <div className="menuDashboard">
                <div className='exportCreateLoadButton' onClick={() => handleExportLoadClick('new')}>
                    <FontAwesomeIcon className='dashboardIcons' icon={faPlane} />
                    <span>Create Load</span>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Load</th>
                        <th>Spent</th>
                        <th>CRG Gross</th>
                        <th>CRG Net</th>
                        <th>T XRF NET</th>
                        <th>T ICP NET</th>
                    </tr>
                </thead>
                <tbody>
                    {exports.map((exp, index) => (
                        <tr key={index} onClick={() => handleExportViewLoadClick(exp._id)}>
                            <td>{formatMonthYear(exp.month)}</td>
                            <td>{exp.load}</td>
                            <td>${exp.lotsAll.usdSpentTotal}</td>
                            <td>${exp.estGrossCrg - exp.lotsAll.usdSpentTotal}</td>
                            <td>${(exp.estGrossCrg - exp.lotsAll.usdSpentTotal - exp.variableExpenses - exp.fixedExpenses - exp.exportExpenses).toFixed(0)}</td>
                            <td>${(exp.estGrossTechXrf - exp.lotsAll.usdSpentTotal - exp.variableExpenses - exp.fixedExpenses - exp.exportExpenses).toFixed(0)}</td>
                            <td>${(exp.estGrossTechIcP - exp.lotsAll.usdSpentTotal - exp.variableExpenses - exp.fixedExpenses - exp.exportExpenses).toFixed(0)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </>
            )}
        </div>
    );
};

export default ExportMain;
