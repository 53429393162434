import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './goalcharts.css';

const GoalProfitChart = () => {
    const [totalMonthlySpent, setTotalMonthlySpent] = useState(0);
    const [totalMonthlyKg, setTotalMonthlyKg] = useState(0);
    const [crgData, setCrgData] = useState(null);
    const navigate = useNavigate();
    const isLocal = window.location.hostname === 'localhost';
    const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';

        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/contacts-monthly-data`);
                const totalSpent = response.data.totalMonthlySpent;
                const totalKilogram = response.data.totalMonthlyKg;

                setTotalMonthlySpent(totalSpent);
                setTotalMonthlyKg(totalKilogram);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };


    const fetchCrgChartData = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/crg/chart`);
            if (!response.ok) {
                throw new Error('Failed to fetch CRG data');
            }
            const crgData = await response.json();
            setCrgData(crgData);
        } catch (error) {
            console.error('Error fetching CRG data:', error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchCrgChartData();
    }, [baseUrl]);


    useEffect(() => {
        createPieChart();
    }, [totalMonthlySpent]);

    const handleDailyChartClick = (event) => {
        const activeElement = window.profitPieChart.getElementsAtEventForMode(event, 'point', window.profitPieChart.options);
        if (activeElement.length > 0) {
            // Navigate to WeeklyContacts component upon clicking the chart
            navigate('/profit-contacts-all');
        }
    };

    const sumUsdValue = () => {
        if (!crgData) return 0;
        const currentMonth = new Date().getMonth();
        return crgData.ceramicsCrg.indCeramicsCrg.reduce((total, item) => {
            if (new Date(item.dateCrg).getMonth() === currentMonth) {
                return total + item.usdValueCrg;
            }
            return total;
        }, 0);
    };

    const sumKilograms = () => {
        if (!crgData) return 0;
        const currentMonth = new Date().getMonth();
        return crgData.ceramicsCrg.indCeramicsCrg.reduce((total, item) => {
            if (new Date(item.dateCrg).getMonth() === currentMonth) {
                return total + item.kilogramsCrg;
            }
            return total;
        }, 0);
    };


    const createPieChart = () => {
        const canvas = document.getElementById('profit-pie-chart');
        if (!canvas) return;

        // Destroy existing chart instance if it exists
        if (window.profitPieChart) {
            window.profitPieChart.destroy();
        }

        const ctx = canvas.getContext('2d');

        const data = {
            labels: ['Left', 'Collected'],
            datasets: [{
                data: [totalMonthlySpent + totalMonthlyKg * 5.32 - sumUsdValue(crgData) - sumKilograms(crgData) * 5.32, sumUsdValue(crgData) + sumKilograms(crgData) * 5.32],
                backgroundColor: ['Red', 'green']
            }]
        };

    // Create the pie chart
    window.profitPieChart = new Chart(ctx, {
        type: 'pie',
        data: data,
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    labels: {
                      color: 'white' // Set legend label color to white
                    }
                },
                title: {
                    display: true,
                    text: 'TOTAL GROSS',
                    color: 'white'
                }
            },
            onClick: handleDailyChartClick
        }
    });
};

    return (
        <div className="goal-charts-main">
            <canvas id="profit-pie-chart"></canvas>
        </div>
    );
};

export default GoalProfitChart;
