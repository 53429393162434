import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './goalcharts.css';

const WeeklyContacts = () => {
    const [weeklyContacts, setWeeklyContacts] = useState([]);
    const isLocal = window.location.hostname === 'localhost';
    const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';

    useEffect(() => {
        const fetchWeeklyContacts = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/contacts-weekly-data`);
                // Sort the weeklyContacts array by userName in alphabetical order
                const sortedContacts = response.data.weeklyData.sort((a, b) => a.userName.localeCompare(b.userName));
                setWeeklyContacts(sortedContacts);
                console.log(weeklyContacts)
            } catch (error) {
                console.error('Error fetching weekly contacts:', error);
            }
        };

        fetchWeeklyContacts();
    }, [baseUrl]);

    // Calculate total number of contacts
    const totalContacts = weeklyContacts.length;

    // Calculate total weekly kilograms
    const totalWeeklyKg = weeklyContacts.reduce((total, contact) => total + contact.thisWeekKg, 0);

    // Calculate total weekly spent
    const totalWeeklySpent = weeklyContacts.reduce((total, contact) => total + contact.thisWeekSpent, 0);

    // Function to format date in a readable format
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };
    

    return (
        <div className="goal-charts-second">
            <div>
                <p>Total Contacts: {totalContacts}</p>
                <p>Total Weekly Kg: {totalWeeklyKg.toFixed(0)}</p>
                <p>Total Weekly Spent: ${(totalWeeklySpent + totalWeeklyKg * 5.32).toFixed(0)}</p>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Buyer</th>
                        <th>Next Purchase</th>
                        <th>Number</th>
                        <th>Weekly Kg</th>
                        <th>Weekly Spent</th>
                    </tr>
                </thead>
                <tbody>
                    {weeklyContacts.map(contact => (
                        <tr key={contact._id}>
                            <td>{contact.name}</td>
                            <td>{contact.userName}</td>
                            <td>{formatDate(contact.lastContact)}</td>
                            <td>{contact.number}</td>
                            <td>{contact.thisWeekKg.toFixed(0)}</td>
                            <td>${(contact.thisWeekSpent + contact.thisWeekKg * 5.32).toFixed(0)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default WeeklyContacts;
