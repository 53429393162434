import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './cartpopup.css';

const CartPopup = ({ message }) => {
  const [cartName, setCartName] = useState('');
  const [cartPassword, setCartPassword] = useState('');
  const [selectedCartType, setSelectedCartType] = useState('');
  const [showPopup, setShowPopup] = useState(true);
  // eslint-disable-next-line
  const [cartId, setCartId] = useState(null);
  const [carts, setCarts] = useState([]);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCarts = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Get the userId from localStorage
        const response = await fetch(
          `https://lgpfzus0.api.sanity.io/v2021-10-21/data/query/production?query=*%5B_type%20%3D%3D%20%22cart%22%20%26%26%20userId%20%3D%3D%20%22${userId}%22%5D%20%7B%0A%20%20_id%2C%0A%20%20userId%2C%0A%20%20cartName%2C%0A%20%20totalValue%2C%0A%20%20kilogram%0A%7D%0A%0A`
        );

        if (response.ok) {
          const cartData = await response.json();
          setCarts(cartData.result);
        } else {
          console.error('Error fetching cart documents:', response.status);
        }
      } catch (error) {
        console.error('Error fetching cart documents:', error);
      }
    };

    fetchCarts();
  }, []);

  const handleCreateCart = async () => {
    if (cartName.length >= 2 && cartPassword.length >= 5 && selectedCartType) {
      try {
        const userId = localStorage.getItem('userId'); // Get the userId from localStorage
        const response = await fetch(`${baseUrl}/api/cart`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ cartName, cartPassword, cartType: selectedCartType, userId }), // Include userId in the request body
        });

        if (response.ok) {
          const cartDocument = await response.json();
          console.log('Created cart document:', cartDocument);
          setCartId(cartDocument._id);
          setShowPopup(false); // Hide the popup after cart is created

          // Navigate to different URLs based on selectedCartType
          if (selectedCartType === 'CRG Prices') {
            navigate(`/cart/${cartDocument._id}?cartName=${cartName}`);
          } else if (selectedCartType === 'Custom Prices') {
            navigate(`/cartcustom/${cartDocument._id}?cartName=${cartName}`);
          }
        } else {
          console.error('Error creating cart document:', response.status);
        }
      } catch (error) {
        console.error('Error creating cart document:', error);
      }
    }
  };

  const handleAccessCart = async (cartId) => {
    try {
      const response = await fetch(`${baseUrl}/api/cart/access`, {
       
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cartId }), // Send the cartId in the request body
      });

      if (response.ok) {
        const cartDocument = await response.json();
        console.log('Accessed cart document:', cartDocument);
        setCartId(cartDocument._id); // Set the cartId from the response
        setShowPopup(false); // Hide the popup after accessing cart

        // Check the existing cart type and navigate accordingly
        if (cartDocument.cartType === 'CRG Prices') {
          navigate(`/cart/${cartDocument._id}?cartName=${cartDocument.cartName}`);
        } else if (cartDocument.cartType === 'Custom Prices') {
          navigate(`/cartcustom/${cartDocument._id}?cartName=${cartDocument.cartName}`);
        }
      } else if (response.status === 404) {
        console.error('Cart not found');
      } else {
        console.error('Error accessing cart:', response.status);
      }
    } catch (error) {
      console.error('Error accessing cart:', error);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    navigate('/');
  };

  const isCreateCartDisabled = cartName.length < 2 || cartPassword.length < 5 || !selectedCartType;
  const isAccessCartDisabled = isCreateCartDisabled;

  return (
    <>
      {showPopup && (
        <div className="cartpopup">
          <div className="cartpopup-inner">
            <p className="cartpopup-message">{message}</p>
            <input
              type="text"
              value={cartName}
              onChange={(e) => setCartName(e.target.value)}
              placeholder="Customer Name"
            />
            <input
              type="password"
              value={cartPassword}
              onChange={(e) => setCartPassword(e.target.value)}
              placeholder="Password"
            />
            <div className="cartpopup-carttype">
              <label>
                <input
                  type="radio"
                  value="CRG Prices"
                  checked={selectedCartType === "CRG Prices"}
                  onChange={() => setSelectedCartType("CRG Prices")}
                />
                CRG Prices
              </label>
              <label>
                <input
                  type="radio"
                  value="Custom Prices"
                  checked={selectedCartType === "Custom Prices"}
                  onChange={() => setSelectedCartType("Custom Prices")}
                />
                Custom Prices
              </label>
            </div>
            <button onClick={handleCreateCart} disabled={isCreateCartDisabled}>
              Create Cart
            </button>
            <button className="cartpopup-close" onClick={handleClosePopup}>
              Close
            </button>

            {carts.length > 0 && (
              <table>
                <thead>
                  <tr>
                    <th>Cart Name</th>
                    <th>Total Value</th>
                    <th>Kilogram</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {carts.map((cart) => (
                    <tr key={cart._id}>
                      <td>{cart.cartName}</td>
                      <td>{cart.totalValue}</td>
                      <td>{cart.kilogram}</td>
                      <td>
                        <button className='accessbutton' onClick={() => handleAccessCart(cart._id)}>Access</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CartPopup;

