// src/components/CustomerService.js

import React, { useState } from 'react';
import './cm.css'

const CustomerService = ({ onClose }) => {
    const [inputText, setInputText] = useState('');
    const [response, setResponse] = useState('');
  
    const handleInputChange = (e) => {
      setInputText(e.target.value);
    };
  
    const handleSubmit = async () => {
      // Send user input to Node.js server
      try {
        const response = await fetch('http://localhost:3003/chat', {
          // Update the endpoint URL if needed
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ input: inputText }),
        });
  
        const data = await response.json();
        setResponse(data.output);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  

  return (
    <div className="customer-service-container">
      <div className="customer-service-header">
        <h3>Customer Service</h3>
        <button onClick={onClose}>Close</button>
      </div>
      <div className="customer-service-body">
        <textarea
          placeholder="Type your message..."
          value={inputText}
          onChange={handleInputChange}
        />
        <button onClick={handleSubmit}>Submit</button>
      </div>
      <div className="customer-service-footer">
        <p>Response: {response}</p>
      </div>
    </div>
  );
};

export default CustomerService;
