import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal'; 
import './numberSearch.css';

Modal.setAppElement('#root');

const NumberSearch = ({ baseUrl }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/contacts`);
        setContacts(response.data);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchContacts();
  }, [baseUrl]);

  const handleSearch = () => {
    const contact = contacts.find(c => c.number === searchQuery);
    if (contact) {
      const userName = contact.user ? contact.user.name : 'Unknown User';
      setModalContent(`Number exists. Contact: <strong>${userName}</strong>`);
    } else {
      setModalContent('New customer');
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className='numberSearchMain'>
      <input
        className='customerSearch'
        type="text"
        placeholder="Enter contact number"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <button onClick={handleSearch}>Search</button>
      
      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        contentLabel='Search Result'
        style={{
          overlay: {
            top: '80px',
            width: '100%',
            background: 'black',
          },
          content: {
            margin: 'auto',
          },
        }}
      >
        <div className='modalContent'>
          <h2 dangerouslySetInnerHTML={{ __html: modalContent }}></h2>
          <button className='buttonModalAssignUser' onClick={handleCloseModal}>Close</button>
        </div>
      </Modal>
    </div>
  );
};

export default NumberSearch;
