
import React, { useState } from 'react';
import Modal from 'react-modal';
import { FaEnvelope, FaPhone, FaFacebook, FaWhatsapp, FaGlobe } from 'react-icons/fa';
import './footer.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { PK, EG, AE, SA } from 'country-flag-icons/react/3x2'

const WhatsappPopup = ({ isOpen, onRequestClose, onOptionSelect }) => {

    const customStyles = {
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
      content: {
        top: '55%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
      },
      buttons: {
        margin: '10px 0', // Reduced margin for better spacing
        background: 'black',
        color: 'white',
        borderRadius: '8px',
        cursor: 'pointer',
        padding: '20px',
        fontSize: '16px',
        width: '200px', 
      },
    };
  
    const handleOptionClick = (option) => {
      onOptionSelect(option);
      onRequestClose();
    };
  
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
        contentLabel="Whatsapp Language Popup"
      >
        <div className='button-countries' onClick={() => handleOptionClick('CatalyticsA')}>
         Pakistan <PK title="United States" className="flags"/>
        </div>
        <div className='button-countries' onClick={() => handleOptionClick('CatalyticsE')}>
         Egypt <EG title="United States" className="flags"/>
        </div>
        <div className='button-countries' onClick={() => handleOptionClick('ElectronicsA')}>
         Dubai <AE title="United States" className="flags"/>
        </div>
        <div className='button-countries' onClick={() => handleOptionClick('ElectronicsE')}>
         Saudi Arabia <SA title="United States" className="flags"/>
        </div>
        <div className='button-countries-cancel' onClick={onRequestClose}>
          Cancel
        </div>
      </Modal>
    );
  };
  
  
  const Footer = () => {
    const [isWhatsappPopupOpen, setWhatsappPopupOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('');
  
    const handleWhatsappClick = () => {
      setWhatsappPopupOpen(true);
    };
  
    const handleOptionSelect = (option) => {
      // Handle the selected option (e.g., set language state)
      setSelectedLanguage(option);
      
      // You can also trigger the WhatsApp link based on the selected option
      let whatsappNumber = '+1234567890'; // Default WhatsApp number
      let message = `Hello, I want to communicate in ${option}.`;
  
      if (option === 'CatalyticsA') {
        whatsappNumber = '+923065382842'; // Pakistan
        message = 'السلام عليكم عندي استفسار بخصوص قطع غيار السيارات'; // Custom message for Catalytics
      } else if (option === 'CatalyticsE') {
        whatsappNumber = '+201015832627'; // Egypt
        message = 'السلام عليكم عندي استفسار بخصوص قطع غيار السيارات'; // Custom message for Catalytics
      } else if (option === 'ElectronicsA') {
        whatsappNumber = '+971569551889'; // Dubai
        message = 'السلام عليكم عندي استفسار بخصوص قطع غيار السيارات'; // Custom message for Catalytics
    } else if (option === 'ElectronicsE') {
      whatsappNumber = '+966569070835'; // Saudi Arabia
      message = 'السلام عليكم عندي استفسار بخصوص قطع غيار السيارات'; // Custom message for Catalytics
    }
  
      const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
      window.location.href = whatsappLink;
    };
  
    return (
      <footer className="footer-container">
        <div className="footer-column">
          <h3>Pakistan</h3>
          <p><FaEnvelope /> amirtk@yahoo.com </p>
          <p><FaPhone /> +923065382842</p>
          <p><a href="https://www.facebook.com/profile.php?id=61555812743626" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}><FaFacebook /> Facebook</a></p>
          <p><a href="https://crg-1-pakistan-bf9fced80b73.herokuapp.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}><FaGlobe /> Website</a></p>
        </div>
        <div className="footer-column">
          <h3>Dubai</h3>
          <p><FaEnvelope /> info@crg-recycling.com </p>
          <p><FaPhone /> +971569551889</p>
          <p><a href="https://www.facebook.com/profile.php?id=61555812743626" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}><FaFacebook /> Facebook</a></p>
          <p><a href="https://crg-recycling.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}><FaGlobe /> Website</a></p>
        </div>
        <div className="footer-column">
          <h3>Egypt</h3>
          <p><FaEnvelope /> egypt@crg-recycling.com </p>
          <p><FaPhone />  +201015832627 </p>
          <p><a href="https://www.facebook.com/profile.php?id=61555812743626" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}><FaFacebook /> Facebook</a></p>
          <p><a href="https://www.crg-egypt.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}><FaGlobe /> Website</a></p>
        </div>
        <div className="footer-column">
          <h3>Saudi Arabia</h3>
          <p><FaEnvelope /> info@crg-recycling.com </p>
          <p><FaPhone />  +966569070835 </p>
          <p><a href="https://www.facebook.com/profile.php?id=61555812743626" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}><FaFacebook /> Facebook</a></p>
          <p><a href="https://crg-recycling.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}><FaGlobe /> Website</a></p>
        </div>

        <>
      {/* Floating Message Icon */}
      <div className="floating-icon" onClick={handleWhatsappClick}>
        <FaWhatsapp className="whatsapp-icon" />
      </div>

      {/* Render WhatsappPopup if it is open */}
      {isWhatsappPopupOpen && (
        <WhatsappPopup
          isOpen={isWhatsappPopupOpen}
          onRequestClose={() => setWhatsappPopupOpen(false)}
          onOptionSelect={handleOptionSelect}
        />
      )}
    </>
     
      </footer>
    );
  };
  
  export default Footer;