import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './calendarWarehouse.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComputer, faCar} from '@fortawesome/free-solid-svg-icons';

const ScheduleAdminView = () => {
  const [contactData, setContactData] = useState([]);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/contacts`);
        setContactData(response.data);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchContacts();
  }, [baseUrl]);

  // Get today's date and tomorrow's date
  const today = new Date();
  const tomorrow = new Date();
  const twoDays = new Date();
  tomorrow.setDate(today.getDate() + 1);
  twoDays.setDate(today.getDate() + 2);

  // Filter and sort contacts for today and tomorrow with typeOfContact === 'Visit'
  const filteredContactsToday = contactData
    .filter((contact) => {
      const contactDate = new Date(contact.nextContact);
      return (
        contactDate.toISOString().split('T')[0] === today.toISOString().split('T')[0] &&
        contact.typeOfContact === 'Visit'
      );
    })
    .sort((a, b) => a.user.name.localeCompare(b.user.name)); // Sort alphabetically by contact.user.name

  const filteredContactsTomorrow = contactData
    .filter((contact) => {
      const contactDate = new Date(contact.nextContact);
      return (
        contactDate.toISOString().split('T')[0] === tomorrow.toISOString().split('T')[0] &&
        contact.typeOfContact === 'Visit'
      );
    })
    .sort((a, b) => a.user.name.localeCompare(b.user.name)); 

    const filteredContactsTwoDays = contactData
    .filter((contact) => {
      const contactDate = new Date(contact.nextContact);
      return (
        contactDate.toISOString().split('T')[0] === twoDays.toISOString().split('T')[0] &&
        contact.typeOfContact === 'Visit'
      );
    })
    .sort((a, b) => a.user.name.localeCompare(b.user.name));

  // Count the total number of contacts for today and tomorrow
  const contactCountToday = filteredContactsToday.length;
  const contactCountTomorrow = filteredContactsTomorrow.length;
  const contactCountTwoDays = filteredContactsTwoDays.length;

  const renderMaterialIcon = (materialType) => {
    switch (materialType) {
      case 'Cats':
        return <FontAwesomeIcon className='iconMaterial' icon={faCar} />;
      case 'Weee':
        return <FontAwesomeIcon className='iconMaterial' icon={faComputer} />;
      case 'Both':
        return (
          <>
            <FontAwesomeIcon className='iconMaterial' icon={faCar} />
            <FontAwesomeIcon className='iconMaterial' icon={faComputer} />
          </>
        );
      default:
        return null;
    }
  };

  // Function to count contacts by user name
  function countContactsByUserName(contacts) {
    return contacts.reduce((acc, contact) => {
      acc[contact.user.name] = (acc[contact.user.name] || 0) + 1;
      return acc;
    }, {});
  }

  // Function to render contact list and counts for a specific day
  function renderContacts(day, contacts) {
    // Count the number of contacts for each userName
    const contactCount = countContactsByUserName(contacts);

    return (
      <div className='warehouse-box-day'>
        {/* Display the total count for the day */}
        <h2>{day.toDateString()} - Visits {contacts.length}</h2>
       
       <div className='userVisits'>
        {Object.entries(contactCount).map(([userName, count]) => (
          <div key={userName}>
            {userName}: {count}
          </div>
        ))}
        </div>
        {/* Display the contact list */}
        <div className='userVisitList'>
        {contacts.map((contact) => (
          <div key={contact._id}>
            <span className="warehouse-material-type">{contact.user.name}</span> - {''}
            <span className="warehouse-contact-name">{contact.name}</span> - {''}
            <span className="warehouse-contact-name">{contact.city}</span> {''}
            {renderMaterialIcon(contact.materialType)}
              <FontAwesomeIcon/>
          </div>
        ))}
         </div>
      </div>
    );
  }

  return (
    <div className='warehouse-container'>
      {/* Render today's contacts */}
      {renderContacts(today, filteredContactsToday)}
      {/* Render tomorrow's contacts */}
      {renderContacts(tomorrow, filteredContactsTomorrow)}
            {/* Render tomorrow's contacts */}
            {renderContacts(twoDays, filteredContactsTwoDays)}
    </div>
  );
};

export default ScheduleAdminView;
