import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../userAuth';

const CrgStockMain = () => {
    const { isLoggedIn } = useAuth();
    const title = localStorage.title;
    const navigate = useNavigate();
    useEffect(() => {
      if (!isLoggedIn) {
        navigate('/login');
      }
    }, [isLoggedIn, navigate]);
    const [ceramicsStock, setCeramicsStock] = useState([]);
    const [exportsStock, setExportsStock] = useState([]);
    const [crgData, setCrgData] = useState(null);
    const isLocal = window.location.hostname === 'localhost';
    const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';

    const fetchCeramicsStock = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/ceramics`);
            setCeramicsStock(response.data[0].ceramicsCrg.indCeramicsCrg);
            setCrgData(response.data[0])
        } catch (error) {
            console.error('Error fetching ceramics data:', error);
        }
    };

    const fetchExportsInStock = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/exports/instock`);
            setExportsStock(response.data);
        } catch (error) {
            console.error('Error fetching export data:', error);
        }
    };

    useEffect(() => {
        fetchCeramicsStock();
        fetchExportsInStock();
    }, []);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Month starts from 0
        const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of year
    
        // Pad single digits with leading zero
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;
    
        return `${formattedDay}/${formattedMonth}/${year}`;
    }

    const sumKilograms = () => {
        if (!crgData) return 0;
        return crgData.ceramicsCrg.indCeramicsCrg.reduce((total, item) => {
                return total + item.kilogramsCrg;
        }, 0);
    };

    const sumSpent = () => {
        if (!crgData) return 0;
        return crgData.ceramicsCrg.indCeramicsCrg.reduce((total, item) => {
                return total + item.usdSpentCrg                ;
        }, 0);
    };

    

    return (
        <div className="exports-second">
                        {isLoggedIn && title === 'Admin' && (
    <>
            <h2 className='h1HeadersExportDetailsExpenses'>Barrel Stock = {sumKilograms().toFixed(2)} = ${sumSpent()}</h2>
            <table className='tableStock'>
                         <thead className='tableStock'>
                            <tr className='tableStock'>
                            <th className='table50Goal'>Date</th>
                                <th className='table50Goal'>Customer</th>
                                <th className='table50Goal'>Spent</th>
                                <th className='table50Goal'>Kilograms</th>
                                <th className='table50Goal'>Gross</th>
                                <th className='table50Goal'>Pt</th>
                                <th className='table50Goal'>Pd</th>
                                <th className='table50Goal'>Rh</th>
                        {/* Add more columns as needed */}
                    </tr>
                </thead>
                <tbody className='tableBodyStock'>
                    {ceramicsStock.map(item => (
                        <tr className='inputStockDetails'  key={item._id}>
                            <td className='inputStockDetails'>{formatDate(item.dateCrg)}</td>
                            <td className='inputStockDetails'>{item.contactCrg.name}</td>
                            <td className='inputStockDetails' >${item.usdSpentCrg}</td>
                            <td className='inputStockDetails' >{item.kilogramsCrg}</td>
                            <td className='inputStockDetails' >${item.usdValueCrg}</td>
                            <td className='inputStockDetails' >{item.ptPpm}</td>
                            <td className='inputStockDetails' >{item.pdPpm}</td>
                            <td className='inputStockDetails' >{item.rhPpm}</td>
                            {/* Render more columns if needed */}
                        </tr>
                    ))}
                </tbody>
            </table>
            

            {exportsStock.map(exportItem => (
                <div key={exportItem._id}>
                       <h2 className='h1HeadersExportDetailsExpenses'>{exportItem.load} Stock = {exportItem.lotsAll.kilogramTotal.toFixed(0)} Kg = ${exportItem.lotsAll.usdSpentTotal}</h2>
                      <table className='tableStock'>
                         <thead className='tableStock'>
                            <tr className='tableStock'>
                                <th className='table50Goal'>Date</th>
                                <th className='table50Goal'>Customer</th>
                                <th className='table50Goal'>Spent</th>
                                <th className='table50Goal'>Kilograms</th>
                                <th className='table50Goal'>Gross</th>
                                <th className='table50Goal'>Pt</th>
                                <th className='table50Goal'>Pd</th>
                                <th className='table50Goal'>Rh</th>
                                {/* Add more columns as needed */}
                            </tr>
                        </thead>
                        <tbody className='tableBodyStock'>
                            {exportItem.lotsAll.indLots.map(lot => (
                                <tr className='inputStockDetails' key={lot._id}>
                                    <td className='inputStockDetails'>{formatDate(lot.dateInd)}</td>
                                    <td className='inputStockDetails'>{lot.contactCrg.name}</td>
                                    <td className='inputStockDetails'>${lot.usdSpentInd}</td>
                                    <td className='inputStockDetails'>{lot.kilogramsInd}</td>
                                    <td className='inputStockDetails'>${lot.usdValueInd}</td>
                                    <td className='inputStockDetails'>{lot.ptPpm}</td>
                                    <td className='inputStockDetails'>{lot.pdPpm}</td>
                                    <td className='inputStockDetails'>{lot.rhPpm}</td>
                                    {/* Render more columns if needed */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}
                </>
            )}
        </div>
    );
};

export default CrgStockMain;
