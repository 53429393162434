import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../userAuth';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMoneyBill1Wave, faTrash} from '@fortawesome/free-solid-svg-icons';
import './editvariableexpenses.css'

const EditVariableExpenses = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  const [variableExpenses, setvariableExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newExpense, setNewExpense] = useState({
    type: '',
    spentUsd: 0,
    spentEgp: 0,
    responsiblePerson: {
      name: '',
    },
    note: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [conversionRate, setConversionRate] = useState(1); // Default conversion rate
  const [convertedUsd, setConvertedUsd] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [expenseToDelete, setExpenseToDelete] = useState(null);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';



  useEffect(() => {
    // Fetch variable expenses data from your API endpoint
    axios.get(`${baseUrl}/api/crg/variable-expenses`)
      .then(response => {
        setvariableExpenses(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching variable expenses data:', error);
        setLoading(false);
      });
  }, [baseUrl]);

  const handleEditExpense = (index, updatedExpense) => {
    // Update the expense at the specified index
    const newExpenses = [...variableExpenses];
    newExpenses[index] = updatedExpense;

    // Make API request to update the variable expenses data on the server
    axios.put(`${baseUrl}/api/crg/variable-expenses`, { variableExpenses: newExpenses })
      .then(response => {
        setvariableExpenses(response.data);
        setIsEditing(false);
        setEditingIndex(null);
      })
      .catch(error => {
        console.error('Error updating variable expenses:', error);
      });
  };

  const handleAddExpense = () => {
    // Make API request to add the new expense on the server
    axios.post(`${baseUrl}/api/crg/variable-expenses`, { newExpense })
      .then(response => {
        setvariableExpenses(response.data);
        setNewExpense({
          type: '',
          spentUsd: 0,
          spentEgp: 0,
          responsiblePerson: {
            name: '',
          },
          note: '',
        });
      })
      .catch(error => {
        console.error('Error adding new expense:', error);
      });
  };
  

    // Calculate total expenses sum
    const totalUsd = variableExpenses.reduce((sum, expense) => sum + expense.spentUsd, 0);
    const totalEgp = variableExpenses.reduce((sum, expense) => sum + expense.spentEgp, 0);

    const handleConversion = () => {
        // Convert and update the total USD value
        const newConvertedUsd = totalUsd + (totalEgp / conversionRate);
        setConvertedUsd(newConvertedUsd);
      };
    
      const handleDeleteExpense = (index) => {
        setExpenseToDelete(index);
        setShowDeleteConfirmation(true);
      };
    
      const confirmDeleteExpense = async () => {
        try {
          const response = await axios.delete(`${baseUrl}/api/crg/variable-expenses/${expenseToDelete}`);
          setvariableExpenses(response.data);
          setExpenseToDelete(null);
          setShowDeleteConfirmation(false);
        } catch (error) {
          console.error('Error deleting expense:', error);
        }
      };
    
      const cancelDeleteExpense = () => {
        setExpenseToDelete(null);
        setShowDeleteConfirmation(false);
      };

      return (
            <div className='variable-expenses-container'>
                        {isLoggedIn ? (
            <>
              <h1>Variable Expenses</h1>
              <div className='variableExpensesDetailsBox'>
                <div className='variableExpensesDetailsBox'>Total USD: ${totalUsd}</div>
                <div className='variableExpensesDetailsBox'>Total EGP: {totalEgp} EGP</div>
                <div className='variableExpensesDetailsBox'>Converted USD: ${convertedUsd.toFixed(2)}</div>
              </div>
              <div>
                <div className='variableExpensesDetailsBox'>
                  Enter EGP/USD Rate:
                  <input
                    type="number"
                    value={conversionRate}
                    onChange={(e) => setConversionRate(e.target.value)}
                  />
                </div>
                <div className='variableExpensesDetailsConvertBox' onClick={handleConversion}> Convert to USD <FontAwesomeIcon icon={faMoneyBill1Wave}/></div>
                
              </div>
      {loading ? (
        <p>Loading data...</p>
      ) : (
        <div className='variable-expenses-Second'>
          <table className="expenses-table">
            <thead>
            <tr>
                <th data-label="Type">Type</th>
                <th data-label="Spent USD">Spent USD</th>
                <th data-label="Spent EGP">Spent EGP</th>
                <th data-label="Responsible Person">Responsible Person</th>
                <th data-label="Note">Note</th>
                <th data-label="Edit">Edit</th>
                <th data-label="Delete">Delete</th>
            </tr>

            </thead>
            <tbody>
              {variableExpenses.map((expense, index) => (
                <tr key={index}>
                  <td>{isEditing && editingIndex === index ? (
                    <input
                      type="text"
                      value={newExpense.type}
                      onChange={(e) => setNewExpense({ ...newExpense, type: e.target.value })}
                    />
                  ) : expense.type}</td>

                  <td>{isEditing && editingIndex === index ? (
                    <input
                      type="number"
                      value={newExpense.spentUsd}
                      onChange={(e) => setNewExpense({ ...newExpense, spentUsd: e.target.value })}
                    />
                  ) : expense.spentUsd}</td>

                  <td>{isEditing && editingIndex === index ? (
                    <input
                      type="number"
                      value={newExpense.spentEgp}
                      onChange={(e) => setNewExpense({ ...newExpense, spentEgp: e.target.value })}
                    />
                  ) : expense.spentEgp}</td>

                  <td>{isEditing && editingIndex === index ? (
                    <input
                      type="text"
                      value={newExpense.responsiblePerson.name}
                      onChange={(e) => setNewExpense({
                        ...newExpense,
                        responsiblePerson: { name: e.target.value },
                      })}
                    />
                  ) : expense.responsiblePerson.name}</td>

                  <td>{isEditing && editingIndex === index ? (
                    <input
                      type="text"
                      value={newExpense.note}
                      onChange={(e) => setNewExpense({ ...newExpense, note: e.target.value })}
                    />
                  ) : expense.note}</td>

                  <td>
                    {isEditing && editingIndex === index ? (
                      <button type="button" onClick={() => handleEditExpense(editingIndex, newExpense)}>
                        Save
                      </button>
                    ) : (
                        <div>
                      <FontAwesomeIcon className='editvariableExpensesIcons' icon={faEdit} onClick={() => {
                        setIsEditing(true);
                        setEditingIndex(index);
                        setNewExpense({
                          type: expense.type,
                          spentUsd: expense.spentUsd,
                          spentEgp: expense.spentEgp,
                          responsiblePerson: {
                            name: expense.responsiblePerson.name,
                          },
                          note: expense.note,
                        });
                      }}/>
                      

                      </div>
                    )}
                  </td>
                  <td>                     
                  <FontAwesomeIcon className='editvariableExpensesIcons' icon={faTrash} onClick={() => handleDeleteExpense(index)} />
                </td>
                </tr>
              ))}
            </tbody>
          </table>

          {!isEditing && (
            <div>
              <h2>Add New Expense</h2>
              <form>
                <button type="button" onClick={handleAddExpense}>
                Add Expense
              </button>
            </form>
            </div>
          )}
        </div>
      )}
    </>
  ) : (
    <p>Please log in to view fixed expenses.</p>
  )}

  {showDeleteConfirmation && (
    <div className="delete-confirmation-overlay">
      <div className="delete-confirmation-modal">
        <p>Are you sure you want to delete this expense?</p>
        <button onClick={confirmDeleteExpense}>Yes</button>
        <button onClick={cancelDeleteExpense}>No</button>
      </div>
    </div>
  )}
</div>
);
};

export default EditVariableExpenses;
