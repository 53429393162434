import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../userAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import './contactForm.css';

Modal.setAppElement('#root');

const ContactForm = ({ onContactAdded }) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = location.state?.userInfo || {};
  const userId = location.state?.userInfo.id || {};
  const userName = location.state?.userInfo.name || {};

  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3001' : 'https://crg-egypt.herokuapp.com';

  const [newContact, setNewContact] = useState({
    name: '',
    number: '',
    address: '',
    lastContact: '',
    nextContact: '',
    typeOfContact: 'Call',
    materialType: 'Cats',
    notes: [],
  });

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewContact((prevContact) => ({ ...prevContact, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Fetch all contacts
      const response = await axios.get(`${baseUrl}/api/contacts`);
      const contacts = response.data;

      // Check if any contact has the same number
      const contactExists = contacts.some(contact => contact.number === newContact.number);

      if (contactExists) {
        // Contact exists, show modal
        setModalContent('Contact with this number already exists.');
        setShowModal(true);
        return;
      }

      // Contact does not exist, proceed with creating a new contact
      const currentDate = new Date();
      const oneMonthAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());

      const newContactResponse = await axios.post(`${baseUrl}/api/contacts`, { ...newContact, userId, userName });
      onContactAdded(newContactResponse.data);

      navigate('/contact-list', { state: { userInfo } });

      setNewContact({
        name: '',
        number: '',
        address: '',
        lastContact: '',
        nextContact: '',
        typeOfContact: 'Call',
        materialType: 'Cats',
        notes: [],
        nextPurchase: oneMonthAgo,
      });
    } catch (error) {
      console.error('Error adding contact:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className='contactFormMain'>
      {isLoggedIn && (
        <div className='contactFormSecond'>
          <div className='contactFormThird'>
            <form onSubmit={handleSubmit}>
              <label className='label'>Name:</label>
              <input type="text" name="name" value={newContact.name} onChange={handleChange} required />

              <label>Number:</label>
              <input type="text" name="number" value={newContact.number} onChange={handleChange} />

              <label>Address:</label>
              <input type="text" name="address" value={newContact.address} onChange={handleChange} />

              <label className='label'>Last Contact:</label>
              <input type="date" name="lastContact" value={newContact.lastContact} onChange={handleChange} />

              <label className='label'>Next Contact:</label>
              <input type="date" name="nextContact" value={newContact.nextContact} onChange={handleChange} />

              <label className='label'>Type of Contact:</label>
              <select name="typeOfContact" value={newContact.typeOfContact} onChange={handleChange}>
                <option value="Call">Call</option>
                <option value="Visit">Visit</option>
                <option value="Delivery">Delivery</option>
              </select>
              <label className='label'>Material:</label>
              <select name="materialType" value={newContact.materialType} onChange={handleChange}>
                <option value="Weee">Weee</option>
                <option value="Cats">Cats</option>
                <option value="Both">Both</option>
              </select>
              <button className='bottombutton' type="submit">Add Customer</button>
            </form>
          </div>
        </div>
      )}
      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        contentLabel='Contact Exists'
        style={{
          overlay: {
            top: '80px',
            width: '100%',
            background: 'black',
          },
          content: {
            margin: 'auto',
          },
        }}
      >
        <div className='modalContent'>
          <h2>{modalContent}</h2>
          <button className='buttonModalAssignUser' onClick={handleCloseModal}>Close</button>
        </div>
      </Modal>
      </div>
  );
};

export default ContactForm;
  
