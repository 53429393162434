import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../userAuth';
import './tasks.css'

const Tasks = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const [tasks, setTasks] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSteps, setSelectedSteps] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';
  const [formData, setFormData] = useState({
    title: '',
    leaderId: '',
    leaderName: '',
    responsibleId: '', // New state to store responsible person's ID
    responsibleName: '', // New state to store responsible person's name
    description: '',
    deadline: '',
    steps: [],
  });
  const [currentTask, setCurrentTask] = useState(null);
  const [users, setUsers] = useState([]);

  // Handle input change for form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle step input change
  const handleStepInputChange = (index, e) => {
    const newSteps = [...formData.steps];
    newSteps[index][e.target.name] = e.target.value;
    setFormData({ ...formData, steps: newSteps });
  };

  // Add a new step
  const addStep = () => {
    setFormData({ ...formData, steps: [...formData.steps, { label: '', info: '' }] });
  };

    // Remove a step
    const removeStep = (index) => {
        const newSteps = [...formData.steps];
        newSteps.splice(index, 1);
        setFormData({ ...formData, steps: newSteps });
      };
    


  // Function to fetch all tasks from the server
  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/tasks`);
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

    // Function to fetch all users from the server
    const fetchUsers = async () => {
        try {
          const response = await axios.get(`${baseUrl}/api/usersall`);
          setUsers(response.data);
          console.log(users)
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      };

  // Handle form submission for adding/editing tasks
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const taskData = { ...formData }; 
      if (currentTask) {
        await axios.put(`${baseUrl}/api/tasks/${currentTask._id}`, taskData);
      } else {
        await axios.post(`${baseUrl}/api/tasks`, taskData);
      }
      fetchTasks();
      setFormData({
        title: '',
        leaderId: '',
        leaderName: '',
        responsibleId: '',
        responsibleName: '',
        description: '',
        deadline: '',
        steps: [],
      });
      setCurrentTask(null);
    } catch (error) {
      console.error('Error adding/editing task:', error);
    }
  };

  // Fetch tasks and users on component mount
  useEffect(() => {
    fetchTasks();
    fetchUsers();
  }, []);
  // Function to set current task for editing
  const editTask = (task) => {
    setCurrentTask(task);
    setFormData({
        title: task.title || '',
        leaderId: task.leader.id || '',
        leaderName: task.leader.name || '',
        responsibleId: task.responsible.id || '', // Set responsible person's ID
        responsibleName: task.responsible.name || '', // Set responsible person's name
        description: task.description || '',
        deadline: task.deadline ? task.deadline.slice(0, 10) : '',
        steps: task.steps ? [...task.steps] : [],
      });
  };

  // Function to delete a task
  const deleteTask = async (taskId) => {
    try {
      await axios.delete(`${baseUrl}/api/tasks/${taskId}`);
      fetchTasks(); // Refetch tasks after deletion
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const handleResponsibleChange = (e) => {
    const { name, value } = e.target;
    // Find the corresponding user object using the value (user ID) from the dropdown
    const selectedUser = users.find(user => user._id === value);
    // Update the formData state with the selected user's ID and name
    setFormData({ ...formData, responsibleId: value, responsibleName: selectedUser ? selectedUser.name : '' });
  };

  const handleLeaderChange = (e) => {
    const { name, value } = e.target;
    // Find the corresponding user object using the value (user ID) from the dropdown
    const selectedLeader = users.find(user => user._id === value);
    // Update the formData state with the selected user's ID and name
    setFormData({ ...formData, leaderId: value, leaderName: selectedLeader ? selectedLeader.name : '' });
  };

  // Handle step click to open modal
  const handleStepClick = (task) => {
    setSelectedSteps(task.steps);
    setCurrentTask(task);
    setModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setModalOpen(false);
    setSelectedSteps([]);
  };

  const handleCheckboxChange = async (index, completed) => {
    const updatedSteps = [...selectedSteps]; // Create a copy of selectedSteps array
    updatedSteps[index].completed = completed; // Update the completed field of the corresponding step
    setSelectedSteps(updatedSteps); // Update the state with the updated steps
  
    try {
      // Send PUT request to update the completed status of the step
      await axios.put(`${baseUrl}/api/tasks/${currentTask._id}/steps/${index}/completed`, { completed });
      // If successful, fetch the updated tasks
      fetchTasks();
    } catch (error) {
      console.error('Error updating step completed status:', error);
    }
  };
  
    // Calculate progress based on step completion and deadline
    const calculateProgress = (task) => {
        const totalSteps = task.steps.length;
        const completedSteps = task.steps.filter((step) => step.completed).length;
        let progress = (completedSteps / totalSteps) * 100; // Progress based on completed steps
    
        // Add 20% progress for deadline if it's not reached yet
        if (new Date(task.deadline).getTime() > Date.now()) {
          progress += 20;
        }
    
        // Cap the progress at 100% if it exceeds
        progress = Math.min(progress, 100);
    
        return progress;
      };
    
// Function to determine color based on progress and deadline proximity
const determineColor = (progress, deadline) => {
    const timeLeft = new Date(deadline).getTime() - Date.now();
    const daysLeft = Math.ceil(timeLeft / (1000 * 3600 * 24));
  
    if (daysLeft <= 2) {
      return 'red';
    } else if (progress <= 50) {
      return 'red';
    } else if (progress <= 75) {
      return 'orange';
    } else {
      return 'green';
    }
  };
  
    
      // Fetch tasks and users on component mount
      useEffect(() => {
        fetchTasks();
        fetchUsers();
      }, []);

  return (
    <div className="tasks-container">
{isLoggedIn && title === 'Admin' && (
    <>
      <form onSubmit={handleSubmit}>
      <div className="tasks-container-row">
      <div className="tasks-container-column">
        <input className='inputTask'
          type="text"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          placeholder="Title"
        />
        <select className='selectTask'
        name="leaderId"
        value={formData.leaderId}
        onChange={handleLeaderChange}
        >
        <option value="">Select Responsible Person ...</option>
        {users.map(user => (
            <option key={user._id} value={user._id}>{user.name}</option>
        ))}
        </select>
        <select className='selectTask'
        name="responsibleId"
        value={formData.responsibleId}
        onChange={handleResponsibleChange}
        >
        <option value="">Select Who To Assign To ...</option>
        {users.map(user => (
            <option key={user._id} value={user._id}>{user.name}</option>
        ))}
        </select>
        <input className='inputTask'
          type="text"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          placeholder="Description"
        />
        <input className='inputTask'
          type="date"
          name="deadline"
          value={formData.deadline}
          onChange={handleInputChange}
          placeholder="Deadline"
        />
        </div>
        <div className="tasks-container-column">
            {/* Inputs for steps */}
            {formData.steps.map((step, index) => (
              <div key={index}>
                <input className='inputTask'
                  type="text"
                  name="label"
                  value={step.label}
                  onChange={(e) => handleStepInputChange(index, e)}
                  placeholder="Step Label"
                />
                <input className='inputTask'
                  type="text"
                  name="info"
                  value={step.info}
                  onChange={(e) => handleStepInputChange(index, e)}
                  placeholder="Step Info"
                />
                <button className='stepButton' type="button" onClick={() => removeStep(index)}>Remove Step</button>
              </div>
            ))}
            <button className='stepButton' type="button" onClick={addStep}>Add Another Step</button>
          </div>
        </div>
        <button className='stepButton' type="submit">{currentTask ? 'Update Task' : 'Add Task'}</button>
      </form>
  
      <table className='tasksTable'>
  <thead className='tasksTable'>
    <tr className='tasksTable'>
      <th  className='table50header'>Title</th>
      <th  className='table50header'>Steps</th>
      <th  className='table50header'>Responsible</th>
      <th  className='table50header'>Assigned</th>
      <th  className='table50header'>Description</th>
      <th  className='table50header'>Deadline</th>
      <th  className='table50header'>Progress</th>
      <th  className='table50header'>Actions</th>
    </tr>
  </thead>
  <tbody className='table50'>
          {/* Display the list of tasks */}
          {tasks.map((task) => {
            // Calculate progress
            const progress = calculateProgress(task);

    // Determine color based on progress and deadline
    const color = determineColor(progress, task.deadline);

            return (
              <tr key={task._id}>
                <td>{task.title}</td>
                <td className='stepClickOpenModal' onClick={() => handleStepClick(task)}>{task.steps.length}</td>
                <td>{task.leader.name}</td>
                <td>{task.responsible.name}</td>
                <td>{task.description}</td>
                <td>{new Date(task.deadline).toLocaleDateString()}</td>
                <td>
                  {/* Progress bar */}
                  <div className="progress-bar-container">
                    <div className="progress-bar" style={{ width: `${progress}%`, backgroundColor: color }}>
                      {progress}%
                    </div>
                  </div>
                </td>
                <td>
                  {/* Button to edit task */}
                  <button onClick={() => editTask(task)}>Edit</button>
                  {/* Button to delete task */}
                  <button onClick={() => deleteTask(task._id)}>Delete</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Modal for steps */}
      {modalOpen && (
  <div className="modal">
    <div className="modal-task-steps-body-column">
     
        {selectedSteps.map((step, index) => (
          <div key={index}>
            <div className="modal-task-steps-body">
            <div className='modalSteps'>{step.label}</div>
            
              <div className="modal-task-steps-body-ticks">
                <input
                  className='modalStepsTickBox'
                  type="checkbox"
                  id={`completed-${index}`}
                  name={`completed-${index}`}
                  checked={step.completed} // Check if the step is completed
                  onChange={(e) => handleCheckboxChange(index, e.target.checked)} // Handle checkbox change
                />
                <label className='modalStepsTickBoxTitle' htmlFor={`completed-${index}`}>Complete</label>
              </div>
              <div className="modal-task-steps-body-ticks">
                <input
                  className='modalStepsTickBox'
                  type="checkbox"
                  id={`incomplete-${index}`}
                  name={`incomplete-${index}`}
                  checked={!step.completed} // Check if the step is incomplete
                  onChange={(e) => handleCheckboxChange(index, !e.target.checked)} // Handle checkbox change
                />
                <label className='modalStepsTickBoxTitle' htmlFor={`incomplete-${index}`}>Incomplete</label>
              </div>
            </div>
          </div>
        ))}
         <div className="closeStepModal" onClick={closeModal}>Close</div>
         <div>
      </div>
    </div>
  </div>
)}
    </>
)}

    </div>
  );
};


export default Tasks;
