import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'; // Import moment for date formatting
import Calendar from 'react-calendar'; // Import the Calendar component
import './notesMain.css'

function NotesMain() {
  const [notes, setNotes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedNoteId, setSelectedNoteId] = useState(null); // Track the ID of the selected note
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';
  const [newNote, setNewNote] = useState({
    title: '',
    content: '',
    deadline: '',
  });

  const tableRef = useRef(null); // Reference to the table element

  useEffect(() => {
    fetchNotes();
  }, []);

  const fetchNotes = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/notes`);
      const data = await response.json();
      // Sort notes by deadline date
      const sortedNotes = data.sort((a, b) => new Date(a.deadline) - new Date(b.deadline));
      setNotes(sortedNotes);
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };

  const handleAddNote = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setNewNote(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseUrl}/api/notes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newNote),
      });
      if (response.ok) {
        setShowModal(false);
        fetchNotes();
      } else {
        console.error('Failed to add note');
      }
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const eventsOnDate = notes.filter(note => moment(note.deadline).format('YYYY-MM-DD') === formattedDate);
      const hasNotes = eventsOnDate.length > 0;
  
      return (
        <div className={`tile-content ${hasNotes ? 'has-notes' : ''}`}>
          {eventsOnDate.map((event, index) => (
            <div key={index} className="event-marker-notes" onClick={() => handleNoteClick(event._id)}>
              {event.title}
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const handleDelete = async (noteId) => {
    try {
      const response = await fetch(`${baseUrl}/api/delete/noteCrg/${noteId}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        fetchNotes();
        setSelectedNoteId(null); // Clear selected note ID
      } else {
        console.error('Failed to delete note');
      }
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };
  
  
  // Function to handle note click
  const handleNoteClick = (noteId, title) => {
    setSelectedNoteId(noteId);
    // Remove highlight from previously selected row
    const prevSelectedRow = tableRef.current.querySelector('.selected');
    if (prevSelectedRow) {
      prevSelectedRow.classList.remove('selected');
    }
    // Scroll to the selected note in the table
    if (tableRef.current) {
      const selectedNoteElement = tableRef.current.querySelector(`[data-note-id="${noteId}"]`);
      if (selectedNoteElement) {
        selectedNoteElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        // Highlight the selected row in green
        selectedNoteElement.classList.add('selected');
      }
    }
  };

  return (
    <div className="exports-second">
      <div className="exports-third-row">
        <h1 className='h1HeadersExportDetailsExpenses'>CRG Notes</h1>
        <div className='exportCreateLoadButton' onClick={handleAddNote}>
          <FontAwesomeIcon className='exportDetailIcons' icon={faPencil} />
          <span>Create Note</span>
        </div>
      </div>
      {/* Render the calendar */}
      <div>
        <Calendar className={'notes-calendar'}
          tileContent={tileContent}
          locale="en-US"
        />
      </div>
      {/* Table for displaying notes */}
      <table className='table50' ref={tableRef}>
        <thead className='table50'>
          <tr className='table50'>
            <th className='table50th'>Title</th>
            <th className='table50th'>Deadline</th>
            <th className='table50th'>Content</th>
            <th className='table50th'>Delete</th>
          </tr>
        </thead>
        <tbody>
  {notes.map(note => (
    <tr key={note._id} data-note-id={note._id} className={selectedNoteId === note._id ? 'selected' : ''}>
      <td className='inputExportDetails'>{note.title}</td>
      <td className='inputExportDetails'>{note.deadline ? new Date(note.deadline).toLocaleString() : '-'}</td>
      <td className='inputExportDetailsType'>{note.content}</td>
      <td className='inputExportDetailsDate'>
      <div className='iconDeleteNoteCrg'  onClick={() => handleDelete(note._id)}>
        <FontAwesomeIcon icon={faTrash} />
      </div>
    </td>
    </tr>
  ))}
</tbody>

      </table>
      {/* Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h2 className='h1HeadersExportDetailsExpenses'>Add Note</h2>
            <form onSubmit={handleSubmit}>
              <input className='inputTask' type="text" name="title" placeholder="Title" value={newNote.title} onChange={handleChange} />
              <textarea className='inputTask' name="content" placeholder="Content" value={newNote.content} onChange={handleChange} />
              <label>Deadline</label>
              <input className='inputTask' type="datetime-local" name="deadline" value={newNote.deadline} onChange={handleChange} />
              <button className='expenseAllButton' type="submit">Add Note</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default NotesMain;
