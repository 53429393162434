import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Popup from '../admin/popup';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import PopupSubmit from './popupcart';
import axios from 'axios';
import './cart.css';

function CartCustom() {
  const { cartId } = useParams();
  const cartName = new URLSearchParams(useLocation().search).get('cartName');
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [kilogram, setKilogram] = useState('');
  const [customPrice, setCustomPrice] = useState('');
  const [totalValue, setTotalValue] = useState(0);
  const [cartTotalValue, setCartTotalValue] = useState(0);
  const [cartProfitUsd, setCartProfitUsd] = useState(0);
  const [cartFullValue, setCartFullValue] = useState(0);
  const [cartKilogram, setCartKilogram] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sortingOption, setSortingOption] = useState('name'); // Default sorting option is 'name'
  const tableRef = useRef(null);
  const [showPopupSubmit, setShowPopupSubmit] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(null);
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';


const handleExportPDF = () => {
  if (!tableRef.current) return;

  setIsLoading(true);

  // Increase font size for table header
  const tableHeaders = tableRef.current.getElementsByTagName('th');
  for (let i = 0; i < tableHeaders.length; i++) {
    tableHeaders[i].style.fontSize = '30px'; // Adjust the font size as needed
  }

  // Reduce font size for better fit in table cells
  const tableCells = tableRef.current.getElementsByTagName('td');
  for (let i = 0; i < tableCells.length; i++) {
    tableCells[i].style.fontSize = '20px'; // Adjust the font size as needed
  }

  html2canvas(tableRef.current).then(canvas => {
    const tableData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'pt', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Calculate the total height required for the table
    const tableHeight = canvas.height * (pdfWidth / canvas.width);

    // Set the height of the PDF document to accommodate the table
    pdf.internal.pageSize.setHeight(tableHeight + 100);

    pdf.setFillColor('#d28753');
    pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');

    const tableYPosition = 80; // Set table Y position to 30px

    pdf.addImage(tableData, 'PNG', 0, tableYPosition, pdfWidth, tableHeight);

    let watermarkImage = new Image();
    watermarkImage.src = require('../logo.png');
    watermarkImage.onload = () => {
      const imageWidth = 100;
      const imageHeight = (watermarkImage.height / watermarkImage.width) * imageWidth;
      const xPos = pdfWidth - imageWidth - 10;
      const yPos = 0;

      pdf.addImage(watermarkImage, 'PNG', xPos, yPos, imageWidth, imageHeight);

      const textYPosition = tableYPosition - 60;
      const textXPosition = 10;
      pdf.setFontSize(12);
      pdf.text(`TEL: 01019997899`, textXPosition, textYPosition);
      pdf.text(`EMAIL: Farouk@crg-recycling.com`, textXPosition, textYPosition + 15);
      pdf.text(`Total Kilograms: ${cartKilogram ? Number(cartKilogram).toFixed(2) : ''}`, textXPosition, textYPosition + 40);
      pdf.text(`Total Value: EGP ${cartTotalValue ? Number(cartTotalValue).toFixed(2) : ''}`, textXPosition, textYPosition + 55);

      pdf.save('cart_table.pdf');

      setIsLoading(false);

      for (let i = 0; i < tableHeaders.length; i++) {
        tableHeaders[i].style.fontSize = ''; // Reset to the default font size
      }

      for (let i = 0; i < tableCells.length; i++) {
        tableCells[i].style.fontSize = ''; // Reset to the default font size
      }
    };
  });
};

  
useEffect(() => {
  const fetchExchangeRate = async () => {
    try {
      const response = await axios.get('/api/exchangeRate');
      setExchangeRate(response.data.exchangeRate);
    } catch (error) {
      console.error('Error fetching exchange rate:', error);
    }
  };

  fetchExchangeRate();
}, []);

  

    useEffect(() => {
      setIsLoading(true);
      fetch(`${baseUrl}/api/documents`)
        .then(response => response.json())
        .then(data => {
          const sortedDocuments = data.sort((a, b) => a.weeName.localeCompare(b.weeName)); // Sort the documents alphabetically
          setDocuments(sortedDocuments);
          setIsLoading(false);
        })
      .catch(error => {
        console.error('Error fetching documents:', error);
        setIsLoading(false);
      });
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/carts/${cartId}`)
      .then(response => response.json())
      .then(data => {
        setCartItems(data);
      })
      .catch(error => {
        console.error('Error fetching cart items:', error);
        setIsLoading(false);
      });
  }, [cartId, baseUrl]);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${baseUrl}/api/carts/value/${cartId}`)
      .then(response => response.json())
      .then(data => {
        setCartTotalValue(data.totalValue);
        setCartKilogram(data.kilogram);
        setCartProfitUsd(data.profitUsd);
        setCartFullValue(data.fullValue);
      })
      .catch(error => {
        console.error('Error fetching cart items:', error);
        setIsLoading(false);
      });
  }, [cartId, baseUrl]); // Add baseUrl as a dependency
  

  const handleDocumentSelect = event => {
    const selectedDocumentId = event.target.value;
    const selectedDoc = documents.find(doc => doc._id === selectedDocumentId);
    setSelectedDocument(selectedDoc);
    setKilogram('');
    setTotalValue(0);
  };

  const handleKilogramChange = e => {
    const enteredKilogram = e.target.value;
    setKilogram(enteredKilogram);
  
    // Calculate total value based on the custom price
    const calculatedValue = customPrice * enteredKilogram;
    setTotalValue(calculatedValue);
  };
  
  

  const handleAddToCart = async () => {
    try {
      if (!selectedDocument) return;
      setIsLoading(true);
  
      const newCartItem = {
        weeName: selectedDocument.weeName,
        kilogram: Number(kilogram),
        egp: selectedDocument.egp,
        customPrice: Number(customPrice),
        profitUsd: ((((selectedDocument.priceHundred.toFixed(2) * 0.93 -1.40) - customPrice / exchangeRate) * Number(kilogram)).toFixed(2)),
        fullValue: (((selectedDocument.priceHundred.toFixed(2) * 0.93 -1.40) * Number(kilogram)).toFixed(2)),
        itemProfit: (((selectedDocument.priceHundred.toFixed(2) * 0.93 -1.40) - customPrice / exchangeRate).toFixed(2) / (selectedDocument.priceHundred.toFixed(2)  * 0.93 -0.70 ).toFixed(2)).toFixed(2)
      };
  
      // Calculate total value based on the custom price and kilogram
      newCartItem.totalValue = newCartItem.customPrice * newCartItem.kilogram;
  
      const cartItemResponse = await fetch(`${baseUrl}/api/cartItems/customprice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newCartItem),
      });
  
      if (cartItemResponse.ok) {
        const createdCartItem = await cartItemResponse.json();
  
        const cartResponse = await fetch(`${baseUrl}/api/carts/${cartId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ cartItem: createdCartItem }),
        });
  
        if (cartResponse.ok) {
          const updatedCart = await cartResponse.json();
          console.log(updatedCart);
          setCartItems(prevCartItems => [...prevCartItems, createdCartItem]);
          setKilogram('');
          setTotalValue(0);
          setSelectedDocument(null);
  
          // Fetch the updated cart total value
          fetch(`${baseUrl}/api/carts/value/${cartId}`)
            .then(response => response.json())
            .then(data => {
              setCartTotalValue(data.totalValue);
              setCartKilogram(data.kilogram);
              setCartProfitUsd(data.profitUsd);
              setCartFullValue(data.fullValue);
              setIsLoading(false);
            })
            .catch(error => {
              console.error('Error fetching cart items:', error);
            });
        } else {
          console.error('Error updating cart:', cartResponse.statusText);
        }
      } else {
        console.error('Error creating cart item:', cartItemResponse.statusText);
      }
    } catch (error) {
      console.error('Error creating cart item:', error);
    }
  };
  

const handleSortingOptionChange = event => {
  setSortingOption(event.target.value);
};

// Sorting the cart items based on the selected sorting option
const sortedCartItems = [...cartItems];
if (sortingOption === 'name') {
  sortedCartItems.sort((a, b) => a.weeName.localeCompare(b.weeName));
} else if (sortingOption === 'kilogram') {
  sortedCartItems.sort((a, b) => b.kilogram - a.kilogram);
} else if (sortingOption === 'egp') {
  sortedCartItems.sort((a, b) => b.egp - a.egp);
} else if (sortingOption === 'total_egp') {
  sortedCartItems.sort((a, b) => b.totalValue - a.totalValue); // Sort in descending order
}

const dropdownOptions = documents.map(document => (
  <option key={document._id} value={document._id}>
    {`${document.weeName} - EGP ${document.egp.toFixed(0)}`}
  </option>
));

const handlePopupOpen = () => {
  setShowPopupSubmit(true);
};

const cartTable = (
  <table ref={tableRef}>
    <thead>
      <tr>
        <th>Grade</th>
        <th>EGP / KG</th>
        <th>Kilogram</th>
        <th>Total EGP</th>
      </tr>
    </thead>
    <tbody>
         {sortedCartItems.map(item => (
            <tr key={item._id}>
                <td>{item.weeName}</td>
                <td>{item.customPrice.toFixed(0)}</td>
                <td>{item.kilogram}</td>
                <td>{item.totalValue ? item.totalValue.toFixed(0) : ''}</td>
            </tr>
            ))
            }
    </tbody>
  </table>
);

return (
  <div className='cartpage'>
    {isLoading && <Popup message="Data is Updating ..." />}
    <h2>Customer: {cartName}</h2>
    <div className='cartheader'>
      <div className='kgvalue'>
        <div className='titles'>CART</div>
        {cartTotalValue || cartKilogram ? (
          <>
            <div className='titles'>EGP {cartTotalValue ? Number(cartTotalValue).toFixed(0) : ''}</div>
            <div className='titles'>KG {cartKilogram ? Number(cartKilogram).toFixed(0) : ''}</div>
          </>
        ) : (
          <div className='titles'>Empty</div>
        )}
      </div>
      <div className='kgvalue'>
  <div className='titles'>EGP / KG</div>
  {cartTotalValue || cartKilogram ? (
    <>
      <div className='titles'>
        {cartTotalValue ? (Number(cartTotalValue).toFixed(0) / Number(cartKilogram).toFixed(0)).toFixed(0) : ''}
      </div>
      {localStorage.getItem('title') === 'Admin' && cartProfitUsd && cartFullValue && (
        <div className='titles'>
          {(Number(cartProfitUsd).toFixed(2))}
        </div>
      )}
    </>
  ) : (
    <div className='titles'>Empty</div>
  )}
</div>


    </div>
    <select onChange={handleDocumentSelect}>
      <option value="">Select Grade</option>
      {dropdownOptions}
    </select>

    {selectedDocument && (
      <div>
        <p>Grade: {selectedDocument.weeName}</p>
        <p>EGP Value: {selectedDocument.egp.toFixed(0)} / KG</p>
        <input
          type="number"
          value={customPrice}
          onChange={e => setCustomPrice(e.target.value)}
          placeholder="Enter Price"
        />
        <input
          type="number"
          value={kilogram}
          onChange={handleKilogramChange}
          placeholder="Enter Kilogram"
        />
        <p>Total Value: {totalValue.toFixed(0)}</p>
        {localStorage.getItem('title') === 'Admin' && (
            <p>{(((selectedDocument.priceHundred.toFixed(2) * 0.93 - 1.40) - customPrice / exchangeRate).toFixed(2) / (selectedDocument.priceHundred.toFixed(2) * 0.93 - 1.40).toFixed(2)).toFixed(2)* 100}%</p>
          )}
        <button onClick={handleAddToCart}>Add to Cart</button>
        <div className='card-container'>
          <div className='newcard'>
            {selectedDocument.weeImageOne && (
              <img src={selectedDocument.weeImageOne} alt="" />
            )}
          </div>
        </div>
      </div>
    )}
    {cartItems.length > 0 && (
      <div>
        <div>
          <label htmlFor="sortingOption">Sort by: </label>
          <select className='sort' id="sortingOption" value={sortingOption} onChange={handleSortingOptionChange}>
            <option value="name">Name</option>
            <option value="kilogram">Kilogram</option>
            <option value="egp">EGP</option>
            <option value="total_egp">Total EGP</option>
          </select>
        </div>
        {cartTable}
        <div className='bottomgroup'>
          <button className='bottombutton' onClick={handleExportPDF}>Export as PDF</button>
          <button className='bottombutton' onClick={handlePopupOpen}>Submit</button>
        </div>
        {showPopupSubmit && <PopupSubmit cartId={cartId} />}
      </div>
    )}
  </div>
);
}

export default CartCustom;

