import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../userAuth';

const AllFinalExpenses = () => {
    const { isLoggedIn } = useAuth();
    const title = localStorage.title;
    const [expenses, setExpenses] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
      if (!isLoggedIn) {
        navigate('/login');
      }
    }, [isLoggedIn, navigate]);
    const isLocal = window.location.hostname === 'localhost';
    const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';

    useEffect(() => {
        fetchExpenses();
    }, []);

    const fetchExpenses = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/expenses`); 
            setExpenses(response.data);
        } catch (error) {
            console.error('Error fetching expenses:', error);
        }
    };

    const handleCreateExpense = async () => {
        const currentDate = new Date();
        const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);

        try {
            await axios.post(`${baseUrl}/api/expenses`, { month: nextMonth });
            // Assuming successful creation, fetch expenses again to update the list
            fetchExpenses();
        } catch (error) {
            console.error('Error creating expense:', error);
        }
    };

    const handleExpenseClick = (expenseId) => {
        navigate(`/crg-monthly-expense/k;aslkdjfe[aigjfdhsktuwrept9w0ereur2i-wuarfiopskgjfkl;j/${expenseId}`);
    };

    return (
        <div className="tasks-container">
                                            {isLoggedIn && title === 'Admin' && (
    <>
            <div className="calendar">
                {expenses.map((expense, index) => (
                    <div key={index} onClick={() => handleExpenseClick(expense._id)} className="expense-item">
                        <p>{new Date(expense.month).toLocaleString('default', { month: 'long', year: 'numeric' })}</p>
                        {/* You can add more details from the expense object if needed */}
                    </div>
                ))}
                <div className="expense-item add-expense" onClick={handleCreateExpense}>
                    <FontAwesomeIcon icon={faPlus} />
                </div>
            </div>
            </>
            )}
        </div>
    );
    
    
};

export default AllFinalExpenses;
