import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function ImageUploader() {
  const { documentId } = useParams();
  const [logoUrl, setLogoUrl] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:3003' : 'https://crg-egypt.herokuapp.com';

  const handleUpdateLogoUrl = async () => {
    try {
      await axios.put(`${baseUrl}/weemain/${documentId}`, { weeImageOne: logoUrl });
      console.log('Logo URL updated successfully');
      // Add any additional logic or state updates after a successful update
    } catch (error) {
      console.error('Error updating logo URL:', error);
      // Handle error case and provide user feedback if necessary
    }
  };

  const handleUpdatePrice = async () => {
    try {
      await axios.put(`${baseUrl}/weemain/${documentId}/price`, { price: price });
      console.log('Price updated successfully');
      // Add any additional logic or state updates after a successful update
    } catch (error) {
      console.error('Error updating price:', error);
      // Handle error case and provide user feedback if necessary
    }
  };

  const handleUpdateDescription = async () => {
    try {
      await axios.put(`${baseUrl}/weemain/${documentId}/description`, { description: description });
      console.log('Description updated successfully');
      // Add any additional logic or state updates after a successful update
    } catch (error) {
      console.error('Error updating description:', error);
      // Handle error case and provide user feedback if necessary
    }
  };

  return (
    <div>
      <div>
        <label>Logo URL:</label>
        <input
          type="text"
          value={logoUrl}
          onChange={(e) => setLogoUrl(e.target.value)}
        />
        <button onClick={handleUpdateLogoUrl}>Update Logo URL</button>
      </div>
      <div>
        <label>Price:</label>
        <input
          type="text"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <button onClick={handleUpdatePrice}>Update Price</button>
      </div>
      <div>
        <label>Description:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={10}
          cols={50}
          maxLength={1000}
        />
        <button onClick={handleUpdateDescription}>Update Description</button>
      </div>
    </div>
  );
}

export default ImageUploader;
